// 使用环境变量中的API URL
const API_BASE_URL = process.env.VUE_APP_API_URL;

// 获取完整的API URL
export function getApiUrl(path) {
    // 确保path以/开头
    const normalizedPath = path.startsWith('/') ? path : `/${path}`;
    return `${API_BASE_URL}${normalizedPath}`;
}

// 导出环境标识
export const ENV = {
    isDevelopment: process.env.NODE_ENV === 'development',
    isProduction: process.env.NODE_ENV === 'production'
};
