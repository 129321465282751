<template>
  <div class="public-page">
    <NavBar />
    <div class="page-content">
      <div class="container">
        <div class="content-wrapper">
          <!-- 登录后显示切换标签 -->
          <div v-if="isLoggedIn" class="mb-4">
            <ul class="nav nav-tabs custom-tabs">
              <li class="nav-item">
                <a class="nav-link" :class="{ active: currentTab === 'public' }" 
                   @click.prevent="switchTab('public')" href="#">
                  大厅
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{ active: currentTab === 'my' }" 
                   @click.prevent="switchTab('my')" href="#">
                  我的提示词
                </a>
              </li>
            </ul>
          </div>

          <FilterSort 
            v-model:searchQuery="searchQuery"
            v-model:workspaceFilter="workspaceFilter"
            v-model:currentSort="currentSort"
            :workspaces="availableWorkspaces"
            :loading="isLoading"
            :show-workspace-filter="currentTab === 'my'"
            @search="handleSearch"
            @reset-workspace="resetWorkspaceFilter"
          />

          <div v-if="isLoading" class="text-center py-5">
            <LoadingSpinner />
          </div>
          
          <ErrorAlert v-else-if="error" :message="error" />
          
          <EmptyState v-else-if="prompts.length === 0" />
          
          <!-- 当是"我的提示词"标签时 -->
          <template v-else-if="currentTab === 'my'">
            <MyPrompts 
              :prompts="prompts"
              @show-toast="showToast"
            />
            <!-- 添加加载更多按钮 -->
            <div v-if="hasMore" class="text-center mt-4">
              <button 
                class="btn btn-outline-primary"
                @click="loadMore"
                :disabled="isLoading"
              >
                <span v-if="isLoading">
                  <i class="bi bi-arrow-repeat spin me-2"></i>加载中...
                </span>
                <span v-else>
                  <i class="bi bi-plus-lg me-2"></i>加载更多
                </span>
              </button>
            </div>
          </template>
          
          <!-- 当是"大厅"标签时 -->
          <template v-else>
            <PublicPrompts :prompts="prompts" />
            <!-- 添加加载更多按钮 -->
            <div v-if="hasMore" class="text-center mt-4">
              <button 
                class="btn btn-outline-primary"
                @click="loadMore"
                :disabled="isLoading"
              >
                <span v-if="isLoading">
                  <i class="bi bi-arrow-repeat spin me-2"></i>加载中...
                </span>
                <span v-else>
                  <i class="bi bi-plus-lg me-2"></i>加载更多
                </span>
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from './NavBar.vue'
import MyPrompts from './MyPrompts.vue'
import PublicPrompts from './PublicPrompts.vue'
import FilterSort from './FilterSort.vue'
import LoadingSpinner from './LoadingSpinner.vue'
import ErrorAlert from './ErrorAlert.vue'
import EmptyState from './EmptyState.vue'
import apiService, { API_ENDPOINTS } from '@/services/api'

export default {
  name: 'PublicPage',
  components: {
    NavBar,
    MyPrompts,
    PublicPrompts,
    FilterSort,
    LoadingSpinner,
    ErrorAlert,
    EmptyState
  },
  data() {
    return {
      prompts: [], // 初始化为空数组
      currentSort: 'latest', // 改为 'latest' 而不是 'hot'
      searchQuery: '',
      isLoading: false,
      error: null,
      showRegisterDialog: false,
      currentTab: 'public',
      isLoggedIn: false,
      username: '',
      showLoginDialog: false,
      copyingPrompts: {}, // 用于跟踪每个提示词的复制状态
      workspaceFilter: '', // 当前选中的工作空间
      workspaces: new Set(), // 存储所有可用的工作空间
      tabs: [
        { value: 'public', label: '大厅' },
        { value: 'my', label: '我的提示词' }
      ],
      hasMore: true, // 添加 hasMore 属性
    }
  },
  computed: {
    // 添加计算属性获取所有可用的工作空间
    availableWorkspaces() {
      const workspaces = new Set();
      this.prompts.forEach(prompt => {
        if (prompt.workspace) {
          workspaces.add(prompt.workspace);
        }
      });
      return Array.from(workspaces).sort();
    }
  },
  async created() {
    // 确保在组件创建时就检查登录状态
    this.checkLoginStatus();
    this.fetchPrompts();
  },
  methods: {
    checkLoginStatus() {
      const token = localStorage.getItem('token');
      const username = localStorage.getItem('username');
      console.log('[PublicPage] 检查登录状态:', { token: !!token, username });
      
      if (token && username) {
        console.log('[PublicPage] 用户已登录:', username);
        this.isLoggedIn = true;
        this.username = username;
      } else {
        console.log('[PublicPage] 用户未登录');
        this.isLoggedIn = false;
        this.username = '';
        this.currentTab = 'public';
      }
    },
    async fetchPrompts(isLoadMore = false) {
      console.log('[PublicPage] 开始获取提示词, 当前标签:', this.currentTab);
      // this.isLoading = true; loading会重新刷新页面
      this.error = null;
      
      try {
        // 构建请求参数
        const params = new URLSearchParams({
          offset: isLoadMore ? this.prompts.length : 0, // 如果是加载更多，使用当前数组长度作为偏移量
          limit: 20
        });

        // 添加搜索参数
        if (this.searchQuery) {
          params.append('search', this.searchQuery);
        }

        // 添加工作空间过滤
        if (this.workspaceFilter) {
          params.append('workspace', this.workspaceFilter);
        }

        // 添加排序参数
        if (this.currentSort) {
          params.append('sort', this.currentSort);
        }

        let response;
        if (this.currentTab === 'my') {
          console.log('[PublicPage] 获取个人提示词');
          response = await apiService.request(`${API_ENDPOINTS.USER.PROMPTS}?${params}`);
        } else {
          console.log('[PublicPage] 获取公共提示词');
          response = await apiService.request(`${API_ENDPOINTS.PROMPT.LIST}?${params}`);
        }
        
        console.log('[PublicPage] API响应:', response);
        
        if (response.error_code === 0 && response.data && Array.isArray(response.data.prompts)) {
          const newPrompts = response.data.prompts || [];

          // 如果是加载更多，追加数据；否则替换数据
          if (isLoadMore) {
            this.prompts = [...this.prompts, ...newPrompts];
          } else {
            this.prompts = newPrompts;
          }
          
          // 更新是否还有更多数据的状态
          this.hasMore = response.data.has_more || false;
        } else {
          console.warn('[PublicPage] 提示词数据格式不正确:', response);
          if (!isLoadMore) {
            this.prompts = [];
          }
        }
      } catch (error) {
        console.error('[PublicPage] 获取提示词失败:', error);
        this.error = error.message || '加载失败，请稍后重试';
        if (!isLoadMore) {
          this.prompts = [];
        }
      } finally {
        this.isLoading = false;
      }
    },
    sortPrompts(sortType) {
      this.currentSort = sortType
    },
    getRelativeTime(dateString) {
      const now = new Date();
      const updateDate = new Date(dateString);
      const diffTime = Math.abs(now - updateDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      
      if (diffDays === 0) {
        return "今天";
      } else if (diffDays === 1) {
        return "昨天";
      } else if (diffDays < 7) {
        return `${diffDays}天前`;
      } else if (diffDays < 30) {
        const weeks = Math.floor(diffDays / 7);
        return `${weeks}周前`;
      } else if (diffDays < 365) {
        const months = Math.floor(diffDays / 30);
        return `${months}个月前`;
      } else {
        const years = Math.floor(diffDays / 365);
        return `${years}年前`;
      }
    },
    handleRegisterSuccess(userData) {
      console.log('[PublicPage] 注册成功:', userData);
      localStorage.setItem('token', userData.token);
      localStorage.setItem('username', userData.username);
      localStorage.setItem('userAvatar', userData.avatar);
      this.isLoggedIn = true;
      this.username = userData.username;
      this.$refs.toast.show('注册成功！');
      this.fetchPrompts();
    },
    handleLogout() {
      console.log('[PublicPage] 用户登出');
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      this.isLoggedIn = false;
      this.username = '';
      this.currentTab = 'public';
      this.$router.push('/');
    },
    handleLoginSuccess(userData) {
      console.log('[PublicPage] 登录成功:', userData);
      localStorage.setItem('token', userData.token);
      localStorage.setItem('username', userData.username);
      localStorage.setItem('userAvatar', userData.avatar);
      this.isLoggedIn = true;
      this.username = userData.username;
      this.$refs.toast.show('登录成功！');
      this.fetchPrompts();
    },
    async switchTab(tab) {
      console.log('[PublicPage] 切换标签:', tab);
      this.currentTab = tab;
      this.error = null;
      this.isLoading = true;
      
      try {
        await this.fetchPrompts();
      } catch (error) {
        console.error('[PublicPage] 切换标签失败:', error);
        this.error = '切换标签失败，请稍后重试';
      } finally {
        this.isLoading = false;
      }
    },
    // 添加命令类型转换方法
    getCommandType(type) {
      const types = {
        '1': '通用命令',
        '2': '代码分析',
        '3': '代码生',
        '4': '源码阅读'
        // 可以根据需要添加更多类型
      }
      return types[type] || '未知类型'
    },
    // 格式化日期时间
    formatDateTime(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      return date.toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        fractionalSecondDigits: 3  // 添加毫秒显示
      });
    },
    // 切换开/有状态
    async togglePublicStatus(prompt) {
      try {
        const endpoint = prompt.is_public ? 
          API_ENDPOINTS.PROMPT.SET_PRIVATE : 
          API_ENDPOINTS.PROMPT.SET_PUBLIC;
        
        await apiService.request(endpoint, {
          method: 'POST',
          body: JSON.stringify({
            prompt_id: prompt.id
          })
        });
        
        // 显示成功提示
        const toastEl = document.getElementById('successToast');
        if (toastEl) {
          toastEl.querySelector('.toast-body').textContent = 
            `提示词已设置为${!prompt.is_public ? '公开' : '私有'}`;
          
          const bsToast = new window.bootstrap.Toast(toastEl, {
            delay: 2000
          });
          bsToast.show();
        }
        
        // 重新获取数据以确保显示最新状态
        await this.fetchPrompts();
      } catch (error) {
        console.error('切换公开状态失败:', error);
        alert(error.message || '操作失败，请稍后重试');
      }
    },
    // 添加复制提示词的方法
    async copyPromptValue(value, promptId) {
      if (this.copyingPrompts[promptId]) return;
      
      try {
        await navigator.clipboard.writeText(value);
        
        // 更新复制状态
        this.copyingPrompts[promptId] = true;
        
        // 3秒后恢复
        setTimeout(() => {
          this.copyingPrompts[promptId] = false;
        }, 3000);
      } catch (err) {
        console.error('复制失败:', err);
        alert('复制失败，动复制');
      }
    },
    showToast(message) {
      this.$refs.toast.show(message);
    },
    // 重置工作空间过滤
    resetWorkspaceFilter() {
      this.workspaceFilter = '';
    },
    async handleSearch(query) {
      console.log('[PublicPage] 处理搜索:', query);
      this.searchQuery = query;
      await this.fetchPrompts(false);
    },
    // 添加加载更多方法
    async loadMore() {
      if (this.isLoading || !this.hasMore) return;
      await this.fetchPrompts(true);
    }
  },
  watch: {
    // 监听路由变化时重新检查登录状态
    $route: {
      immediate: true,
      handler(to, from) {
        console.log('[PublicPage] 路由变化:', { to, from });
        this.checkLoginStatus();
      }
    }
  }
}
</script>

<style scoped>
.public-page {
  min-height: 100vh;
  background: var(--bg-primary);
  color: var(--text-primary);
}

.page-content {
  padding-top: 60px;
}

.content-wrapper {
  max-width: 980px !important;
  margin: 0 auto;
  min-height: calc(100vh - 60px);
  position: relative;
  padding: 1.5rem 15px;
}

.nav-link {
  color: var(--text-secondary);
  transition: color 0.3s;
}

.nav-link:hover {
  color: var(--text-primary);
}

/* Tab 样式 */
.custom-tabs {
  border-bottom: 2px solid var(--border-color);
  margin-bottom: 1.5rem;
}

.custom-tabs .nav-link {
  color: var(--text-secondary);
  border: none;
  padding: 0.75rem 1rem;
  margin-right: 1.5rem;
  font-weight: 500;
  position: relative;
}

.custom-tabs .nav-link:hover {
  color: var(--text-primary);
}

.custom-tabs .nav-link.active {
  color: var(--primary-color);
  background: transparent;
  font-weight: 600;
}

.custom-tabs .nav-link.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--primary-color);
}

/* 提示词卡片样式 */
.prompt-card {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 1rem;
  transition: transform 0.3s;
}

.prompt-card:hover {
  transform: translateY(-2px);
  border-color: var(--primary-color);
}

.prompt-content {
  background: var(--bg-secondary);
  border-radius: 0.25rem;
  padding: 1rem;
  margin: 1rem 0;
  color: var(--text-primary);
}

.prompt-content pre {
  color: var(--text-primary);
  background: transparent;
  border: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
  font-size: 0.9rem;
  padding: 1rem;
  padding-right: 45px;
  text-align: left !important;
  text-indent: 0 !important;
}

/* 标签样式 */
.badge {
  background: var(--card-bg);
  color: var(--text-secondary);
  border: 1px solid var(--border-color);
  font-weight: normal;
  margin-right: 0.5rem;
}

/* 按钮样式 */
.btn-primary {
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  border: none;
  color: white;
}

.btn-outline-secondary {
  color: var(--text-secondary);
  border-color: var(--border-color);
  background: transparent;
}

.btn-outline-secondary:hover {
  background: var(--card-bg);
  color: var(--primary-color);
  border-color: var(--primary-color);
}

/* 搜索框样式 */
.search-input {
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  padding: 0.5rem 1rem;
}

.search-input:focus {
  background: var(--bg-secondary);
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.2rem rgba(124, 58, 237, 0.25);
  color: var(--text-primary);
}

.search-input::placeholder {
  color: var(--text-secondary);
}

/* 工作空间过滤器样式 */
.workspace-filter {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.workspace-filter:hover {
  border-color: var(--primary-color);
}

/* 下拉菜单样式 */
.dropdown-menu {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
}

.dropdown-item {
  color: var(--text-primary);
}

.dropdown-item:hover {
  background: var(--bg-secondary);
  color: var(--primary-color);
}

/* 分页样式 */
.pagination .page-link {
  background: var(--card-bg);
  border-color: var(--border-color);
  color: var(--text-primary);
}

.pagination .page-link:hover {
  background: var(--bg-secondary);
  color: var(--primary-color);
}

.pagination .active .page-link {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
}

/* 空状态样式 */
.empty-state {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* 加载状态样式 */
.loading-spinner {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 错误状态样式 */
.error-alert {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>





