<template>
  <div class="my-prompts">
    <div class="col-12 mb-4" v-for="prompt in prompts" :key="prompt.id">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-start mb-2">
            <h5 class="card-title mb-0">提示词 #{{ prompt.id }}</h5>
            <span 
              class="badge" 
              :class="prompt.is_public ? 'bg-success' : 'bg-secondary'"
            >
              {{ prompt.is_public ? '公开' : '私有' }}
            </span>
          </div>
          <div class="prompt-content mb-3 position-relative">
            <pre class="p-3 rounded">{{ prompt.value }}</pre>
            <button 
              class="btn btn-outline-secondary btn-sm position-absolute top-0 end-0 m-2"
              @click="copyPrompt(prompt)"
              title="复制提示词"
              :disabled="copyingPrompts[prompt.id]"
            >
              <i :class="['bi', copyingPrompts[prompt.id] ? 'bi-check-lg text-success' : 'bi-clipboard']"></i>
            </button>
          </div>
          <div class="d-flex flex-wrap justify-content-between align-items-center text-muted small">
            <div>
              <span>创建时间: {{ formatUploadTime(prompt.created_at) }}</span>
            </div>
            <div class="btn-group mt-2">
              <button 
                class="btn btn-sm position-relative" 
                :class="prompt.is_public ? 'btn-outline-secondary' : 'btn-outline-success'"
                @click="togglePublicStatus(prompt)"
              >
                {{ prompt.is_public ? '设为私有' : '设为公开' }}
                <span 
                  v-if="showTooltips[prompt.id]"
                  class="tooltip-text"
                  :class="{ 'error': showTooltips[prompt.id] === 'error' }"
                >
                  {{ showTooltips[prompt.id] === 'error' 
                    ? '操作失败，请重试' 
                    : `已设置为${prompt.is_public ? '公开' : '私有'}` 
                  }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from '@/utils/helpers'
import apiService, { API_ENDPOINTS } from '@/services/api'

export default {
  name: 'ProjectPrompts',
  props: {
    prompts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      copyingPrompts: {},
      showTooltips: {}
    }
  },
  methods: {
    formatUploadTime(timestamp) {
      if (!timestamp) return '-';
      try {
        if (typeof timestamp === 'number') {
          return formatDateTime(new Date(timestamp).toISOString());
        }
        return formatDateTime(timestamp);
      } catch (error) {
        console.error('时间格式化错误:', error);
        return '-';
      }
    },
    async copyPrompt(prompt) {
      if (this.copyingPrompts[prompt.id]) return;

      try {
        await navigator.clipboard.writeText(prompt.value);
        this.copyingPrompts[prompt.id] = true;
        
        setTimeout(() => {
          this.copyingPrompts[prompt.id] = false;
        }, 3000);
      } catch (error) {
        console.error('复制失败:', error);
        this.$emit('show-toast', '复制失败，请手动复制');
      }
    },
    async togglePublicStatus(prompt) {
      try {
        const endpoint = prompt.is_public ? 
          API_ENDPOINTS.PROMPT.SET_PRIVATE : 
          API_ENDPOINTS.PROMPT.SET_PUBLIC;
        
        const response = await apiService.request(endpoint, {
          method: 'POST',
          body: JSON.stringify({
            prompt_id: prompt.id
          })
        });
        
        if (response.error_code === 0) {
          // 立即更新本地状态
          prompt.is_public = !prompt.is_public;
          
          // 显示成功提示
          this.showTooltips[prompt.id] = true;
          setTimeout(() => {
            this.showTooltips[prompt.id] = false;
          }, 2000);
        } else {
          throw new Error(response.message || '操作失败');
        }
      } catch (error) {
        console.error('切换公开状态失败:', error);
        // 显示错误提示
        this.showTooltips[prompt.id] = 'error';
        setTimeout(() => {
          this.showTooltips[prompt.id] = false;
        }, 2000);
      }
    }
  }
}
</script>

<style scoped>
.card {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.card-body {
  color: var(--text-primary);
}

.card-title {
  color: var(--text-primary);
}

.prompt-content pre {
  background: var(--bg-secondary) !important;
  border: 1px solid var(--card-border);
  color: var(--text-primary);
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
  font-size: 0.9rem;
  padding: 1rem;
  padding-right: 45px;
  text-align: left !important;
  text-indent: 0 !important;
  border-radius: 0.5rem;
}

.btn-outline-secondary {
  color: var(--text-secondary);
  border-color: var(--border-color);
  background: transparent;
  transition: all 0.2s ease;
}

.btn-outline-secondary:hover {
  background: var(--card-bg);
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.text-muted {
  color: var(--text-secondary) !important;
}

.badge {
  font-weight: normal;
  font-size: 0.8rem;
}

.badge.bg-success {
  background: rgba(25, 135, 84, 0.2) !important;
  color: #28a745;
}

.badge.bg-secondary {
  background: var(--card-bg) !important;
  color: var(--text-secondary);
  border: 1px solid var(--border-color);
}

.prompt-content {
  position: relative;
}

.prompt-content button {
  opacity: 0.8;
  transition: opacity 0.2s;
}

.prompt-content button:hover {
  opacity: 1;
}

.btn-group .btn {
  font-size: 0.8rem;
}

.text-success {
  color: var(--primary-color) !important;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* 添加提示框样式 */
.tooltip-text {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  animation: fadeInOut 2s ease-in-out;
}

.tooltip-text.error {
  background: var(--error-color);
}

.tooltip-text::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

.tooltip-text.error::after {
  border-top-color: var(--error-color);
}
</style> 