<template>
  <div class="public-prompts">
    <div class="col-12 mb-4" v-for="prompt in prompts" :key="prompt.id">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-start mb-2">
            <h5 class="card-title mb-0">提示词 #{{ prompt.id }}</h5>
          </div>
          <div class="prompt-content mb-3 position-relative">
            <pre class="p-3 rounded">{{ prompt.value }}</pre>
            <button 
              class="btn btn-outline-secondary btn-sm position-absolute top-0 end-0 m-2"
              @click="copyPromptValue(prompt.value, prompt.id)"
              title="复制提示词"
              :disabled="copyingPrompts[prompt.id]"
            >
              <i :class="['bi', copyingPrompts[prompt.id] ? 'bi-check-lg text-success' : 'bi-clipboard']"></i>
            </button>
          </div>
          <div class="d-flex flex-wrap justify-content-between align-items-center text-muted small">
            <div>
              <span>创建时间: {{ formatDateTime(prompt.created_at) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PublicPrompts',
  props: {
    prompts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      copyingPrompts: {}
    }
  },
  methods: {
    formatDateTime(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      return date.toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
    },
    async copyPromptValue(value, promptId) {
      if (this.copyingPrompts[promptId]) return;
      
      try {
        await navigator.clipboard.writeText(value);
        this.copyingPrompts[promptId] = true;
        setTimeout(() => {
          this.copyingPrompts[promptId] = false;
        }, 3000);
      } catch (err) {
        console.error('复制失败:', err);
        alert('复制失败，请手动复制');
      }
    }
  }
}
</script>

<style scoped>
.card {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 0.5rem;
  transition: transform 0.3s;
}

.card:hover {
  transform: translateY(-2px);
  border-color: var(--primary-color);
}

.card-body {
  color: var(--text-primary);
}

.card-title {
  color: var(--text-primary);
}

.prompt-content pre {
  background: var(--bg-secondary) !important;
  border: 1px solid var(--card-border);
  color: var(--text-primary);
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
  font-size: 0.9rem;
  padding: 1rem;
  padding-right: 45px;
  text-align: left !important;
  text-indent: 0 !important;
  border-radius: 0.5rem;
}

.btn-outline-secondary {
  color: var(--text-secondary);
  border-color: var(--border-color);
  background: transparent;
}

.btn-outline-secondary:hover {
  background: var(--card-bg);
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.text-muted {
  color: var(--text-secondary) !important;
}

.workspace {
  border-top: 1px solid var(--border-color);
}

.badge {
  font-weight: normal;
  font-size: 0.8rem;
}

.badge.bg-success {
  background: rgba(25, 135, 84, 0.2) !important;
  color: #28a745;
}

.prompt-content {
  position: relative;
}

.prompt-content button {
  opacity: 0.8;
  transition: opacity 0.2s;
}

.prompt-content button:hover {
  opacity: 1;
}

.prompt-content button.btn-success {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
}

.workspace small {
  display: block;
  color: var(--text-secondary);
}

/* 添加动画效果 */
.card {
  transition: all 0.3s ease;
}

.btn {
  transition: all 0.2s ease;
}

.text-success {
  color: var(--primary-color) !important;
}

/* 添加加载动画 */
.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
</style>
