<template>
  <div class="profile-page">
    <NavBar />
    
    <!-- 主要内容区域 -->
    <main class="main-content">
      <div class="content-wrapper py-5">
        <!-- 用户信息卡片 -->
        <div class="profile-card mb-4">
          <div class="row align-items-start">
            <!-- 左侧头像和用户名 -->
            <div class="col-auto text-center">
              <div class="avatar-wrapper mb-3">
                <input 
                  type="file" 
                  ref="avatarInput"
                  @change="handleAvatarChange" 
                  accept="image/*"
                  class="d-none"
                />
                <div class="avatar-container" @click="triggerAvatarUpload">
                  <img 
                    v-if="userAvatar" 
                    :src="userAvatar" 
                    alt="用户头像"
                    class="avatar"
                  >
                  <i v-else class="bi bi-person-circle avatar-placeholder"></i>
                  <div class="avatar-overlay">
                    <i class="bi bi-camera"></i>
                  </div>
                </div>
              </div>
              <h5 class="mb-1">{{ username }}</h5>
              <small class="user-id">ID: {{ userId }}</small>  <!-- 修改这里，移除 text-muted 类 -->
            </div>

            <!-- 中间用户信息 -->
            <div class="col">
              <div class="user-info d-flex flex-wrap mt-3">
                <div class="info-item me-4">
                  <i class="bi bi-envelope"></i>
                  <span>{{ userEmail || '未设置邮箱' }}</span>
                </div>
                <div class="info-item me-4">
                  <i class="bi bi-telephone"></i>
                  <div class="d-flex align-items-center">
                    <span v-if="!isEditingPhone">{{ userPhone || '未设置电话' }}</span>
                    <div v-else class="d-flex align-items-center">
                      <input 
                        type="tel" 
                        v-model="editingPhone"
                        class="form-control form-control-sm me-2"
                        placeholder="请输入手机号"
                        pattern="[0-9]{11}"
                        maxlength="11"
                      >
                      <button 
                        class="btn btn-sm btn-primary me-1" 
                        @click="savePhone"
                        :disabled="!isValidPhone"
                      >
                        保存
                      </button>
                      <button 
                        class="btn btn-sm btn-outline-secondary" 
                        @click="cancelEditPhone"
                      >
                        取消
                      </button>
                    </div>
                    <button 
                      v-if="!isEditingPhone"
                      class="btn btn-link btn-sm text-primary ms-2" 
                      @click="startEditPhone"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </div>
                </div>
                <div class="info-item">
                  <i class="bi bi-calendar3"></i>
                  <span>注册于 {{ formatDateTime(userCreateTime).split('.')[0] }}</span>
                </div>
              </div>
            </div>

            <!-- 右侧统计和设置按钮 -->
            <div class="col-auto">
              <div class="d-flex flex-column align-items-end">
                <!-- 添加设置按钮 -->
                <router-link 
                  to="/settings" 
                  class="btn btn-outline-light mb-3"
                >
                  <i class="bi bi-gear me-2"></i>
                  设置
                </router-link>
                <!-- 统计数据 -->
                <div class="stats-wrapper d-flex">
                  <div class="stat-item text-center px-3">
                    <div class="stat-value">{{ stats.promptCount }}</div>
                    <small class="stat-label">提示词</small>
                  </div>
                  <div class="stat-item text-center px-3">
                    <div class="stat-value">{{ stats.publicPromptCount }}</div>
                    <small class="stat-label">公开</small>
                  </div>
                  <div class="stat-item text-center px-3">
                    <div class="stat-value">{{ stats.projectCount }}</div>
                    <small class="stat-label">项目</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Tab 导航 -->
        <ul class="nav nav-tabs custom-tabs mb-4">
          <li class="nav-item">
            <a 
              class="nav-link" 
              :class="{ active: currentTab === 'prompts' }"
              @click.prevent="currentTab = 'prompts'"
              href="#"
            >
              我的提示词
            </a>
          </li>
          <li class="nav-item">
            <a 
              class="nav-link" 
              :class="{ active: currentTab === 'projects' }"
              @click.prevent="currentTab = 'projects'"
              href="#"
            >
              我的项目
            </a>
          </li>
        </ul>

        <!-- Tab 内容 -->
        <div class="tab-content">
          <!-- 提示词列表 -->
          <div v-if="currentTab === 'prompts'" class="prompts-tab">
            <div v-if="isLoading && !prompts.length" class="text-center py-5">
              <LoadingSpinner />
            </div>
            <div v-else-if="prompts.length">
              <FilterSort 
                v-model:searchQuery="searchQuery"
                v-model:workspaceFilter="workspaceFilter"
                v-model:currentSort="currentSort"
                :workspaces="availableWorkspaces"
                :loading="isLoading"
                @search="handleSearch"
                @reset-workspace="resetWorkspaceFilter"
              />
              <MyPrompts 
                :prompts="prompts"
                @refresh-prompts="fetchPrompts"
                @show-toast="showToast"
              />
              <!-- 添加加载更多按钮 -->
              <div v-if="hasMore" class="text-center mt-4">
                <button 
                  class="btn btn-outline-primary"
                  @click="loadMore"
                  :disabled="isLoading"
                >
                  <span v-if="isLoading">
                    <i class="bi bi-arrow-repeat spin me-2"></i>加载中...
                  </span>
                  <span v-else>
                    <i class="bi bi-plus-lg me-2"></i>加载更多
                  </span>
                </button>
              </div>
            </div>
            <EmptyState v-else />
          </div>

          <!-- 项目列表 -->
          <div v-if="currentTab === 'projects'" class="projects-tab">
            <!-- 添加项目列表标题和操作按钮 -->
            <div class="d-flex justify-content-between align-items-center mb-4">
              <div class="d-flex align-items-center">
                <h4 class="mb-0">项目列表</h4>
                <span class="text-muted ms-2">(共 {{ projects.length }} 个项目)</span>
              </div>
              <button 
                class="btn btn-primary"
                @click="createProject"
              >
                <i class="bi bi-plus-lg me-2"></i>
                创建项目
              </button>
            </div>

            <!-- 加载状态 -->
            <div v-if="isLoading" class="text-center py-5">
              <LoadingSpinner />
            </div>

            <!-- 项目列表内容 -->
            <div v-else-if="projects.length" class="row g-4">
              <!-- 项目卡片列表 -->
              <div class="col-md-6 mb-4" v-for="project in projects" :key="project.id">
                <div class="project-card">
                  <div class="d-flex justify-content-between align-items-start mb-2">
                    <h5 class="card-title">
                      <router-link 
                        :to="`/project/${project.id}`"
                        class="project-link"
                      >
                        {{ project.name }}
                        <i class="bi bi-box-arrow-up-right ms-2"></i>
                      </router-link>
                    </h5>
                  </div>
                  <p class="card-text text-muted">{{ project.description || '暂无描述' }}</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <small class="text-muted">
                      创建时间: {{ formatDateTime(project.create_time) }}
                    </small>
                    <div class="btn-group">
                      <button 
                        class="btn btn-sm btn-outline-primary"
                        @click="editProject(project)"
                      >
                        编辑
                      </button>
                      <button 
                        class="btn btn-sm btn-outline-danger"
                        @click="deleteProject(project.id)"
                      >
                        删除
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 空状态 -->
            <EmptyState v-else />
          </div>
        </div>
      </div>
    </main>

    <ToastMessage ref="toast" />
    <CreateProjectDialog 
      ref="createProjectDialog"
      :is-edit="!!currentEditProject"
      :edit-project="currentEditProject"
      @project-created="fetchProjects"
      @project-updated="fetchProjects"
      @close="currentEditProject = null"
    />
  </div>
</template>

<script>
import NavBar from './NavBar.vue'
import MyPrompts from './MyPrompts.vue'
import LoadingSpinner from './LoadingSpinner.vue'
import EmptyState from './EmptyState.vue'
import ToastMessage from './ToastMessage.vue'
import FilterSort from './FilterSort.vue'
import CreateProjectDialog from './CreateProjectDialog.vue'
import { formatDateTime } from '@/utils/helpers'
import { getProjectStatus, getStatusClass } from '@/utils/projectUtils'
import apiService, { API_ENDPOINTS } from '@/services/api'

export default {
  name: 'ProfilePage',
  components: {
    NavBar,
    MyPrompts,
    LoadingSpinner,
    EmptyState,
    ToastMessage,
    FilterSort,
    CreateProjectDialog
  },
  data() {
    return {
      isLoggedIn: !!localStorage.getItem('token'), // 根据token判断登录状态
      username: '',
      userEmail: '',
      userCreateTime: '',
      currentTab: localStorage.getItem('profile_current_tab') || 'prompts',
      isLoading: false,
      prompts: [],
      projects: [],
      stats: {
        promptCount: 0,
        publicPromptCount: 0,
        projectCount: 0
      },
      searchQuery: '',
      workspaceFilter: '',
      currentSort: 'latest',
      error: null,
      currentEditProject: null,
      userId: null,
      userPhone: null,
      userAvatar: null,
      isEditingPhone: false,
      editingPhone: '',
    }
  },
  computed: {
    availableWorkspaces() {
      const workspaces = new Set();
      this.prompts.forEach(prompt => {
        if (prompt.workspace) {
          workspaces.add(prompt.workspace);
        }
      });
      return Array.from(workspaces).sort();
    },
    filteredAndSortedPrompts() {
      console.log('[ProfilePage] 过滤和排序提示词, 当前数量:', this.prompts.length);
      
      let result = [...this.prompts];

      // 搜索过滤
      if (this.searchQuery.trim()) {
        const query = this.searchQuery.toLowerCase();
        result = result.filter(prompt => 
          prompt.value.toLowerCase().includes(query)
        );
      }

      // 工作空间过滤
      if (this.workspaceFilter) {
        result = result.filter(prompt => 
          prompt.workspace === this.workspaceFilter
        );
      }

      // 排序
      switch (this.currentSort) {
        case 'latest':
          result.sort((a, b) => {
            const dateA = a.upload_time ? new Date(a.upload_time) : new Date(0);
            const dateB = b.upload_time ? new Date(b.upload_time) : new Date(0);
            return dateB - dateA;
          });
          break;
        case 'az':
          result.sort((a, b) => {
            const valueA = a.value || '';
            const valueB = b.value || '';
            return valueA.localeCompare(valueB);
          });
          break;
      }

      console.log('[ProfilePage] 过滤排序后的提示词数量:', result.length);
      return result;
    },
    isValidPhone() {
      return /^1[3-9]\d{9}$/.test(this.editingPhone);
    }
  },
  methods: {
    formatDateTime,
    getProjectStatus,
    getStatusClass,
    
    handleLogout() {
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      this.$router.push('/');
    },
    
    showToast(message) {
      this.$refs.toast.show(message);
    },
    
    async fetchUserInfo() {
      try {
        const data = await apiService.user.getInfo();
        console.log('User info response:', data);

        if (data.data) {
          const userInfo = data.data;
          this.username = userInfo.nickname || userInfo.username;
          this.userEmail = userInfo.email;
          this.userCreateTime = userInfo.created_at;
          this.userId = userInfo.id;
          this.userPhone = userInfo.phone;
          this.userAvatar = userInfo.avatar;
          
          // 更新统计数据
          this.stats.promptCount = userInfo.total_prompts || 0;
          this.stats.publicPromptCount = userInfo.public_prompts || 0;
        }
      } catch (error) {
        console.error('获取用户信息失败:', error);
        this.error = error.message;
        this.showToast(error.message);
      }
    },
    
    async fetchPrompts(isLoadMore = false) {
      console.log('[ProfilePage] 开始获取提示词');
      this.isLoading = true;
      
      try {
        // 构建请求参数
        const params = new URLSearchParams({
          offset: isLoadMore ? this.prompts.length : 0,
          limit: 20
        });

        // 添加搜索参数
        if (this.searchQuery) {
          params.append('search', this.searchQuery);
        }

        // 添加工作空间过滤
        if (this.workspaceFilter) {
          params.append('workspace', this.workspaceFilter);
        }

        // 添加��序参数
        if (this.currentSort) {
          params.append('sort', this.currentSort);
        }

        const response = await apiService.request(`${API_ENDPOINTS.USER.PROMPTS}?${params}`);
        console.log('[ProfilePage] API响应:', response);
        
        if (response.error_code === 0 && response.data) {
          const newPrompts = response.data.prompts || [];
          
          // 如果是加载更多，追加数据；否则替换数据
          if (isLoadMore) {
            this.prompts = [...this.prompts, ...newPrompts];
          } else {
            this.prompts = newPrompts;
          }
          
          // 更新是否还有更多数据的状态
          this.hasMore = response.data.has_more || false;
        } else {
          console.warn('[ProfilePage] API返回错误:', response);
          if (!isLoadMore) {
            this.prompts = [];
          }
        }
      } catch (error) {
        console.error('[ProfilePage] 获取提示词失败:', error);
        this.error = error.message || '加载失败，请稍后重试';
        if (!isLoadMore) {
          this.prompts = [];
        }
      } finally {
        this.isLoading = false;
      }
    },
    
    async fetchProjects() {
      console.log('[ProfilePage] 开始获取项目列表...');
      this.isLoading = true;
      
      try {
        const data = await apiService.user.getProjects();
        console.log('[ProfilePage] 获取到的原始数据:', data);
        
        if (Array.isArray(data.data)) {
          console.log('[ProfilePage] 项目数据是数组，长度:', data.data.length);
          
          this.projects = data.data.map(project => {
            console.log('[ProfilePage] 处理项目数据:', project);
            return {
              id: project.id,
              user_id: project.user_id,
              name: project.name,
              description: project.desc,
              create_time: project.create_time,
              update_time: project.update_time,
              status: project.status
            };
          });
          
          console.log('[ProfilePage] 处理后的项目列表:', this.projects);
          this.stats.projectCount = this.projects.length;
          console.log('[ProfilePage] 更新项目统计数:', this.stats.projectCount);
          
        } else {
          console.warn('[ProfilePage] 项目数据格式不正确:', {
            dataType: typeof data.data,
            data: data.data
          });
          this.projects = [];
        }
      } catch (error) {
        console.error('[ProfilePage] 获取项目失败:', {
          error: error,
          message: error.message,
          stack: error.stack
        });
        this.showToast('获取项目列表失败');
      } finally {
        console.log('[ProfilePage] 项目获取完成，loading 状态设为 false');
        this.isLoading = false;
      }
    },
    
    resetWorkspaceFilter() {
      this.workspaceFilter = '';
    },
    
    createProject() {
      this.$refs.createProjectDialog.showDialog();
    },
    
    editProject(project) {
      console.log('[ProfilePage] 编辑项目:', project)
      this.currentEditProject = {
        id: project.id,
        name: project.name,
        desc: project.description,
        status: project.status,
        create_time: project.create_time,
        update_time: project.update_time
      }
      this.$refs.createProjectDialog.showDialog()
    },
    
    async deleteProject(projectId) {
      if (!confirm('确定要删除这个项目吗？此操作不可恢复。')) {
        return;
      }

      try {
        await apiService.project.delete(projectId);
        this.showToast('项目已删除');
        await this.fetchProjects();
      } catch (error) {
        console.error('删除项目失败:', error);
        this.showToast(error.message || '删除失败，请稍后重试');
      }
    },
    
    async copyPromptValue(value, promptId) {
      if (this.copyingPrompts[promptId]) return;
      
      try {
        await navigator.clipboard.writeText(value);
        
        // 更新复制状态
        this.copyingPrompts[promptId] = true;
        
        // 3秒后恢复
        setTimeout(() => {
          this.copyingPrompts[promptId] = false;
        }, 3000);
      } catch (err) {
        console.error('复制失败:', err);
        alert('复制失败，请手动复制');
      }
    },
    
    triggerAvatarUpload() {
      this.$refs.avatarInput.click();
    },
    
    async handleAvatarChange(event) {
      const file = event.target.files[0];
      if (!file) return;
      
      // 验证文件类型
      if (!file.type.startsWith('image/')) {
        this.showToast('请选择图片文件');
        return;
      }
      
      // 验证文件大小(最大 2MB)
      if (file.size > 2 * 1024 * 1024) {
        this.showToast('图片大小不能超过 2MB');
        return;
      }
      
      try {
        this.isLoading = true;
        const response = await apiService.user.uploadAvatar(file);
        
        if (response.error_code === 0 && response.data) {
          // 更新头像URL
          const avatarUrl = response.data.avatar_url;
          this.userAvatar = avatarUrl;
          
          // 更新 localStorage 中的头像
          localStorage.setItem('userAvatar', avatarUrl);
          
          // 触发自定义事件通知其他组件(比如 NavBar)更新头像
          window.dispatchEvent(new CustomEvent('avatar-updated', {
            detail: { avatarUrl }
          }));
          
          this.showToast('头像更新成功');
        } else {
          throw new Error(response.message || '上传失败');
        }
      } catch (error) {
        console.error('上传头像失败:', error);
        this.showToast(error.message || '上传失败，请稍后重试');
      } finally {
        this.isLoading = false;
        // 清空 input,允许重复选择同一文件
        event.target.value = '';
      }
    },
    startEditPhone() {
      this.editingPhone = this.userPhone || '';
      this.isEditingPhone = true;
    },
    cancelEditPhone() {
      this.isEditingPhone = false;
      this.editingPhone = '';
    },
    async savePhone() {
      if (!this.isValidPhone) {
        this.showToast('请输入正确的手机号');
        return;
      }

      try {
        const response = await apiService.user.updateProfile({
          phone: this.editingPhone
        });

        if (response.error_code === 0) {
          this.userPhone = this.editingPhone;
          this.isEditingPhone = false;
          this.showToast('电话更新成功');
        }
      } catch (error) {
        console.error('更新电话失败:', error);
        this.showToast(error.message || '更新失败，请稍后重试');
      }
    },
    async handleSearch(query) {
      console.log('[ProfilePage] 处理搜索:', query);
      this.searchQuery = query;
      await this.fetchPrompts(false); // 使用 fetchPrompts 方法，传入 false 表示不是加载更多
    },
    // 添加加载更多方法
    async loadMore() {
      if (this.isLoading || !this.hasMore) return;
      await this.fetchPrompts(true);
    }
  },
  async created() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.$router.push('/');
      return;
    }
    
    await Promise.all([
      this.fetchUserInfo(),
      this.fetchPrompts(),
      this.fetchProjects()
    ]);
  },
  watch: {
    currentTab(newTab) {
      localStorage.setItem('profile_current_tab', newTab)
      if (newTab === 'prompts') {
        this.fetchPrompts();
      } else if (newTab === 'projects') {
        this.fetchProjects();
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    const token = localStorage.getItem('token');
    if (!token) {
      next('/');
    } else {
      next();
    }
  }
}
</script>

<style scoped>
.profile-page {
  min-height: 100vh;
  background: var(--bg-primary);
  color: var(--text-primary);
  padding-top: 60px;
}

.main-content {
  background: transparent;
}

.profile-card {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 0.5rem;
  padding: 1.25rem;
}

.avatar-wrapper {
  width: 64px;
  height: 64px;
}

.avatar-placeholder {
  font-size: 64px;
  color: var(--text-secondary);
}

.user-info {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0;
  border-bottom: none;
}

.info-item i {
  font-size: 1rem;
  width: 24px;
  color: var(--primary-color);
  margin-right: 0.5rem;
}

.info-item span {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.stats-wrapper {
  border-left: 1px solid var(--border-color);
  padding-left: 1rem;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: 600;
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1;
}

.stat-label {
  color: var(--text-secondary);
  font-size: 0.8rem;
}

.info-item {
  border-bottom: 1px solid var(--border-color);
}

.info-content label {
  color: var(--text-secondary);
}

.info-content span {
  color: var(--text-primary);
}

.user-stats {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  padding: 0.5rem;
}

.stat-item {
  text-align: center;
  padding: 1rem;
}

/* Tab 样式 */
.custom-tabs {
  border-bottom: 2px solid var(--border-color);
}

.custom-tabs .nav-link {
  color: var(--text-secondary);
  border: none;
  padding: 0.75rem 1rem;
  margin-right: 1.5rem;
  font-weight: 500;
  position: relative;
}

.custom-tabs .nav-link:hover {
  color: var(--text-primary);
}

.custom-tabs .nav-link.active {
  color: var(--primary-color);
  background: transparent;
  font-weight: 600;
}

.custom-tabs .nav-link.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--primary-color);
}

/* 项目卡片样式 */
.card {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 0.5rem;
  transition: transform 0.3s;
}

.card:hover {
  transform: translateY(-5px);
  border-color: var(--primary-color);
}

.card-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--text-primary);
}

.card-text {
  min-height: 3rem;
  color: var(--text-secondary);
}

/* 按钮样式 */
.btn-primary {
  background: var(--primary-color);
  border: none;
  color: white;
}

.btn-primary:hover {
  background: var(--primary-color);
  opacity: 0.9;
  color: white !important;
}

.btn-primary:active {
  background: var(--primary-color) !important;
  color: white !important;
}

.btn-primary:focus {
  background: var(--primary-color);
  color: white;
  box-shadow: 0 0 0 0.2rem rgba(124, 58, 237, 0.25);
}

.btn-outline-primary {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-outline-primary:hover {
  background: var(--primary-color);
  color: white;
}

.btn-outline-light {
  border-color: var(--border-color);
  color: var(--text-primary);
}

.btn-outline-light:hover {
  background: var(--bg-secondary);
  border-color: var(--primary-color);
  color: var(--text-primary);
}

/* 用户 ID 样式 */
.user-id {
  color: var(--text-secondary);
}

/* 响应式样式 */
@media (max-width: 992px) {
  .user-info {
    margin-top: 2rem;
  }
  
  .user-stats {
    margin-top: 2rem;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .stats-wrapper {
    border-left: none;
    border-top: 1px solid var(--border-color);
    margin-top: 1rem;
    padding-top: 1rem;
    padding-left: 0;
  }
  
  .user-info {
    margin: 1rem 0;
  }
  
  .user-stats {
    grid-template-columns: 1fr;
  }
}

/* 确保 content-wrapper 样式在最前面 */
.content-wrapper {
  max-width: 900px !important;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
}

.projects-tab h4 {
  color: var(--text-primary);
  font-size: 1.5rem;
  font-weight: 600;
}

.project-card {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 0.5rem;
  padding: 1.5rem;
  height: 100%;
  transition: all 0.3s ease;
}

.project-card:hover {
  transform: translateY(-3px);
  border-color: var(--primary-color);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.project-card .card-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.project-card .card-title a {
  color: var(--text-primary);
  text-decoration: none;
}

.project-card .card-title a:hover {
  color: var(--primary-color);
}

.project-card .card-text {
  color: var(--text-secondary);
  text-align: left;
  min-height: 3rem;
  margin-bottom: 1rem;
}

.project-card .text-muted {
  color: var(--text-secondary) !important;
}

.badge {
  padding: 0.5em 0.8em;
  font-weight: 500;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.badge-success {
  background-color: var(--success-bg);
  color: var(--success-color);
}

.badge-warning {
  background-color: var(--warning-bg);
  color: var(--warning-color);
}

.badge-danger {
  background-color: var(--error-bg);
  color: var(--error-color);
}

.btn-outline-primary {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-outline-primary:hover {
  background-color: var(--primary-color);
  color: white;
}

.btn-outline-danger {
  color: var(--error-color);
  border-color: var(--error-color);
}

.btn-outline-danger:hover {
  background-color: var(--error-color);
  color: white;
}

.empty-state {
  color: var(--text-secondary);
  padding: 2rem;
  text-align: center;
  background: var(--card-bg);
  border-radius: 0.5rem;
  border: 1px dashed var(--border-color);
}

.project-link {
  color: var(--text-primary);
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
}

.project-link:hover {
  color: var(--primary-color);
}

.project-link i {
  font-size: 0.8em;
  opacity: 0;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.project-link:hover i {
  opacity: 1;
  transform: translateX(0);
}

.project-card:hover .project-link {
  color: var(--primary-color);
}

.project-card:hover .project-link i {
  opacity: 1;
  transform: translateX(0);
}

/* 修改按钮组样式 */
.btn-group .btn {
  padding: 0.25rem 0.75rem;
}

/* 编辑按钮样式 */
.btn-outline-primary {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  background-color: transparent;
}

.btn-outline-primary:hover {
  color: #ffffff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color);
}

/* 删除按钮样式 */
.btn-outline-danger {
  color: var(--error-color) !important;
  border-color: var(--error-color);
  background-color: transparent;
}

.btn-outline-danger:hover {
  color: #ffffff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--error-color);
}

/* 个人信息项样式 */
.profile-info {
  display: flex;
  flex-direction: column;
  gap: 1.5rem; /* 增加信息项之间的间隔 */
  padding: 1rem;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 0.75rem; /* 图标和文本之间的间隔 */
}

.info-item i {
  font-size: 1.25rem;
  color: var(--text-secondary);
  width: 24px; /* 固定图标宽度 */
}

.info-item span {
  color: var(--text-primary);
}

.info-label {
  color: var(--text-secondary);
  margin-right: 0.5rem;
}

/* ID 样式 */
.user-id {
  color: var(--text-secondary);
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

/* 添加头像相关样式 */
.avatar-container {
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 64px;
  color: var(--text-secondary);
}

.avatar-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.avatar-overlay i {
  color: white;
  font-size: 1.5rem;
}

.avatar-container:hover .avatar-overlay {
  opacity: 1;
}

/* 加载状态时显示加载效果 */
.avatar-container.loading {
  opacity: 0.7;
  pointer-events: none;
}

.form-control-sm {
  height: 30px;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.btn-link {
  padding: 0;
  color: var(--primary-color);
  text-decoration: none;
}

.btn-link:hover {
  color: var(--primary-color);
  opacity: 0.8;
}

.info-item .btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

/* 添加移动端适配 */
@media (max-width: 576px) {
  .profile-card {
    padding: 1rem;
  }

  /* 调整头像和用户信息布局 */
  .row.align-items-start {
    flex-direction: column;
  }

  .col-auto {
    width: 100%;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  /* 头像区域调整 */
  .avatar-wrapper {
    margin: 0 auto 1rem;
  }

  /* 用户名和ID调整 */
  h5.mb-1 {
    font-size: 1.25rem;
    margin-bottom: 0.25rem !important;
  }

  .user-id {
    font-size: 0.875rem;
    opacity: 0.8;
  }

  /* 用户信息布局调整 */
  .user-info {
    flex-direction: column;
    gap: 1rem;
    margin-top: 0 !important;
  }

  .info-item {
    width: 100%;
    padding: 0.75rem;
    background: var(--bg-secondary);
    border-radius: 0.5rem;
    margin: 0 !important;
  }

  .info-item i {
    font-size: 1.125rem;
    width: 20px;
  }

  .info-item span {
    flex: 1;
  }

  /* 电话编辑表单调整 */
  .info-item .d-flex {
    width: 100%;
  }

  .form-control-sm {
    height: 36px;
  }

  .btn-sm {
    height: 36px;
    display: flex;
    align-items: center;
  }

  /* 统计数据调整 */
  .stats-wrapper {
    width: 100%;
    border-left: none;
    border-top: 1px solid var(--border-color);
    padding: 1rem 0 0;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
  }

  .stat-item {
    padding: 0.5rem;
    text-align: center;
  }

  .stat-value {
    font-size: 1.25rem;
  }

  .stat-label {
    font-size: 0.75rem;
  }

  /* Tab导航调整 */
  .custom-tabs {
    margin: 0 -1rem;
    padding: 0 1rem;
  }

  .nav-link {
    padding: 0.75rem 0.5rem;
    font-size: 0.9rem;
  }

  /* 项目列表调整 */
  .projects-tab .d-flex {
    flex-direction: column;
    gap: 1rem;
  }

  .projects-tab .btn-primary {
    width: 100%;
  }

  .col-md-6 {
    padding: 0;
  }

  .project-card {
    margin-bottom: 1rem;
    padding: 1rem;
  }

  .project-card .btn-group {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }

  .project-card .btn {
    width: 100%;
  }
}

/* 添加平板适配 */
@media (min-width: 577px) and (max-width: 992px) {
  .profile-card {
    padding: 1.5rem;
  }

  .stats-wrapper {
    padding-left: 1.5rem;
  }

  .project-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}
</style>







