<template>
  <div class="login-page">
    <div class="container">
      <div class="text-center mb-4">
        <router-link to="/" class="d-inline-block">
          <img src="@/assets/app-logo.svg" alt="CursorAI Logo" height="48">
        </router-link>
      </div>
      
      <div class="login-container">
        <h1 class="text-center mb-4">登录到 Cursor</h1>
        
        <div class="login-box">
          <div v-if="errors.submit" class="alert alert-danger mb-3">
            {{ errors.submit }}
          </div>

          <form @submit.prevent="handleSubmit" id="loginForm">
            <div class="mb-3">
              <label class="form-label text-start w-100" for="email">邮箱地址</label>
              <input 
                type="email" 
                class="form-control"
                id="email"
                name="email"
                v-model="form.email"
                placeholder="请输入邮箱"
                required
                autocomplete="username"
              >
            </div>
            
            <div class="mb-3">
              <div class="d-flex justify-content-between">
                <label class="form-label" for="password">密码</label>
                <!-- <a href="#" class="forgot-link">忘记密码？</a> -->
              </div>
              <input 
                type="password" 
                class="form-control"
                id="password"
                name="password"
                v-model="form.password"
                placeholder="请输入密码"
                required
                autocomplete="current-password"
              >
            </div>

            <button 
              type="submit" 
              class="btn btn-primary w-100"
              :disabled="isSubmitting"
            >
              {{ isSubmitting ? '登录中...' : '登录' }}
            </button>
          </form>
        </div>

        <div class="signup-hint">
          还没有账号？
          <router-link to="/register">立即注册</router-link>
        </div>
      </div>

      <div class="footer-links text-center">
        <router-link to="/terms" class="mx-2">服务条款</router-link>
        <router-link to="/privacy" class="mx-2">隐私政策</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from '@/services/api'

export default {
  name: 'LoginPage',
  data() {
    return {
      form: {
        email: process.env.NODE_ENV === 'development' ? '' : '',
        password: process.env.NODE_ENV === 'development' ? '' : ''
      },
      errors: {},
      isSubmitting: false
    }
  },
  methods: {
    validateForm() {
      this.errors = {}
      
      if (!this.form.email) {
        this.errors.email = '请输入邮箱'
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.form.email)) {
        this.errors.email = '请输入有效的邮箱地址'
      }
      
      if (!this.form.password) {
        this.errors.password = '请输入密码'
      }
      
      return Object.keys(this.errors).length === 0
    },
    async handleSubmit() {
      if (!this.validateForm()) return
      
      this.isSubmitting = true
      try {
        console.log('开始登录请求，参数:', {
          email: this.form.email,
          password: this.form.password
        })

        const data = await apiService.auth.login({
          email: this.form.email,
          password: this.form.password,
        });
        
        console.log('登录请求响应数据:', data)
        
        if (data.error_code === 0) {
          console.log('登录成功')
          localStorage.setItem('token', data.token)
          localStorage.setItem('username', data.username)
          localStorage.setItem('userAvatar', data.avatar)
          this.$router.push('/profile')
        } else {
          console.log('登录返回非0状态:', data)
          this.errors.submit = '邮箱或密码错误'
        }
      } catch (error) {
        console.log('登录请求异常:', error)
        this.errors.submit = '网络连接异常，请检查网络后重试'
      } finally {
        this.isSubmitting = false
      }
    }
  }
}
</script>

<style scoped>
.login-page {
  min-height: 100vh;
  background: var(--bg-primary);
  color: var(--text-primary);
  padding: 2rem 0;
}

.login-container {
  max-width: 400px;
  margin: 0 auto;
}

.login-box {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 0.5rem;
  padding: 1.5rem;
  backdrop-filter: blur(10px);
  margin-bottom: 1rem;
}

.form-label {
  color: var(--text-primary);
  font-weight: 500;
}

.form-control {
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  padding: 0.8rem 1rem;
}

.form-control:focus {
  background: var(--bg-secondary);
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.2rem rgba(124, 58, 237, 0.25);
  color: var(--text-primary);
}

.form-control::placeholder {
  color: var(--text-secondary);
}

.forgot-link {
  color: var(--text-secondary);
  text-decoration: none;
  font-size: 0.9rem;
}

.forgot-link:hover {
  color: var(--secondary-color);
  text-decoration: underline;
}

.btn-primary {
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  border: none;
  padding: 0.8rem;
  font-weight: 500;
}

.btn-primary:disabled {
  background: var(--card-bg);
}

.signup-hint {
  text-align: center;
  padding: 1rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 0.5rem;
}

.signup-hint a {
  color: var(--primary-color);
  text-decoration: none;
}

.signup-hint a:hover {
  text-decoration: underline;
}

.footer-links {
  margin-top: 2rem;
}

.footer-links a {
  color: var(--text-secondary);
  text-decoration: none;
  font-size: 0.9rem;
}

.footer-links a:hover {
  color: var(--text-primary);
}

.alert-danger {
  background: rgba(220, 53, 69, 0.1);
  border: 1px solid rgba(220, 53, 69, 0.2);
  color: #dc3545;
}

/* 添加移动端适配 */
@media (max-width: 576px) {
  .login-page {
    padding: 1rem 0;
  }

  .login-container {
    max-width: 100%;
    padding: 0 1rem;
  }

  .login-box {
    padding: 1.25rem;
  }

  h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .form-control {
    padding: 0.6rem 0.8rem;
  }

  .btn-primary {
    padding: 0.6rem;
  }

  .footer-links {
    margin-top: 1.5rem;
  }
}
</style>
