import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/components/HomePage.vue'
import PublicPage from '@/components/PublicPage.vue'
import SettingsPage from '@/components/SettingsPage.vue'
import ProjectsPage from '@/components/ProjectsPage.vue'
import ProfilePage from '@/components/ProfilePage.vue'
import ProjectDetailPage from '@/components/ProjectDetailPage.vue'
import LoginPage from '@/components/LoginPage.vue'
import RegisterPage from '@/components/RegisterPage.vue'
import TermsPage from '@/components/TermsPage.vue'
import PrivacyPage from '@/components/PrivacyPage.vue'
import DownloadPage from '@/components/DownloadPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: {
      title: 'Cursor 提示词助手 - 智能管理您的 AI 提示词',
      metaTags: [
        {
          name: 'description',
          content: '智能记录和管理您的 Cursor AI 提示词，提高开发效率。自动记录、智能分类、一键分享，让提示词管理更简单。'
        },
        {
          name: 'keywords',
          content: 'Cursor AI, 提示词助手, prompt 管理, AI 编程助手'
        }
      ]
    }
  },
  {
    path: '/public',
    name: 'Public',
    component: PublicPage
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: ProjectsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfilePage,
    meta: { 
      requiresAuth: true,
      title: '个人主页'
    }
  },
  {
    path: '/project/:id',
    name: 'ProjectDetail',
    component: ProjectDetailPage
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterPage
  },
  {
    path: '/terms',
    name: 'Terms',
    component: TermsPage
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: PrivacyPage
  },
  {
    path: '/download',
    name: 'Download',
    component: DownloadPage,
    meta: {
      title: '下载 Cursor 提示词助手 - Windows 客户端',
      metaTags: [
        {
          name: 'description',
          content: '下载 Cursor 提示词助手客户端，自动记录和管理您的 AI 提示词。支持 Windows 系统，安装简单，使用方便。'
        }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  // 更新页面标题
  if (to.meta.title) {
    document.title = to.meta.title
  }

  // 更新 meta 标签
  if (to.meta.metaTags) {
    to.meta.metaTags.forEach(tag => {
      const existingTag = document.querySelector(`meta[name='${tag.name}']`)
      if (existingTag) {
        existingTag.setAttribute('content', tag.content)
      } else {
        const newTag = document.createElement('meta')
        newTag.setAttribute('name', tag.name)
        newTag.setAttribute('content', tag.content)
        document.head.appendChild(newTag)
      }
    })
  }

  if (to.meta.requiresAuth) {
    const token = localStorage.getItem('token')
    if (!token) {
      next('/')
      return
    }
  }
  next()
})

export default router
