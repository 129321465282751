import { getApiUrl } from '@/config/api';

// API 端点配置
export const API_ENDPOINTS = {
    // 用户相关
    USER: {
        INFO: '/api/user/info',
        PROMPTS: '/api/user/prompts',
        PROJECTS: '/api/user/projects',
        LOGIN: '/api/login',  // 修改为正确的登录路径
        REGISTER: '/api/register',  // 修改为正确的注册路径
        UPDATE: '/api/user/update',  // 添加用户信息更新路径
        UPDATE_PASSWORD: '/api/user/update-password',  // 添加密码更新路径
        UPLOAD_AVATAR: '/api/user/upload-avatar',  // 添加头像上传路径
    },
    // 提示词相关
    PROMPT: {
        LIST: '/api/prompts',
        SET_PUBLIC: '/api/prompt/set-public',
        SET_PRIVATE: '/api/prompt/set-private',
        UPLOAD: '/api/prompt/upload',  // 添加提示词上传路径
    },
    // 项目相关
    PROJECT: {
        CREATE: '/api/project/create',
        DETAIL: id => `/api/project/detail?id=${id}`,
        UPDATE: '/api/project/update',
        DELETE: id => `/api/project/delete?id=${id}`,
        PROJECT_WORKSPACES: id => `/api/project/workspaces?id=${id}`,
        PROMPTS: id => `/api/project/prompts?id=${id}`,
        ADD_WORKSPACE: (projectId, workspaceId) => 
            `/api/project/add-workspace?projectID=${projectId}&workspaceID=${workspaceId}`,
        REMOVE_WORKSPACE: (projectId, workspaceId) => 
            `/api/project/remove-workspace?projectID=${projectId}&workspaceID=${workspaceId}`,
        WORKSPACES: '/api/workspaces',
    },
    // API密钥相关
    API_KEY: {
        LIST: '/api/api-key/list',
        GENERATE: '/api/api-key/generate',
        DISABLE: id => `/api/api-key/disable?key_id=${id}`,
    },
    // 游戏相关
    GAME: {
        LIST: '/api/games',
        CREATE: '/api/game/create',
        DETAIL: id => `/api/game?id=${id}`,
        UPDATE: '/api/game/update',
        DELETE: id => `/api/game/delete?id=${id}`,
    },
    // 其他
    PAGE: {
        COUNT: '/api/page/count',
    },
    // 博客相关
    BLOG: {
        LIST: '/api/blog/list',
        DETAIL: id => `/api/blog/${id}`,
        LIKE: (id) => `/api/blog/${id}/like`,
    }
};

// 添加文件上传专用函数
async function uploadFile(endpoint, file, options = {}) {
    const url = getApiUrl(endpoint);
    const token = localStorage.getItem('token');
    
    console.log('[Upload API] 开始上传文件:', {
        url,
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type
    });
    
    const formData = new FormData();
    formData.append('avatar', file);
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
            ...options
        });
        
        if (!response.ok) {
            throw new Error(`上传失败: ${response.status}`);
        }
        
        const data = await response.json();
        console.log('[Upload API] 上传响应:', data);
        
        if (data.error_code !== 0) {
            throw new Error(data.message || '上传失败');
        }
        
        return data;
    } catch (error) {
        console.error('[Upload API] 上传失败:', error);
        throw error;
    }
}

// API 请求方法
export const apiService = {
    // 通用请求方法
    async request(endpoint, options = {}) {
        const url = getApiUrl(endpoint);
        const token = localStorage.getItem('token');
        
        console.log('[API Request] 开始请求:', {
            url,
            method: options.method || 'GET',
            hasToken: !!token,
            options
        });
        
        const defaultHeaders = {
            'Content-Type': 'application/json',
            ...(token ? { 'Authorization': `Bearer ${token}` } : {})
        };

        const config = {
            headers: { ...defaultHeaders, ...options.headers },
            ...options
        };

        console.log('[API Request] 完整请求配置:', {
            url,
            config,
            headers: config.headers,
            body: config.body ? JSON.parse(config.body) : undefined
        });

        try {
            const response = await fetch(url, config);
            console.log('[API Response] 状态码:', response.status);
            
            const data = await response.json();
            console.log('[API Response] 响应数据:', {
                endpoint,
                data,
                error_code: data.error_code,
                message: data.message
            });
            
            if (data.error_code === 0) {
                return data;
            } else {
                console.warn('[API Error] 业务错误:', {
                    endpoint,
                    error_code: data.error_code,
                    message: data.message
                });
                throw new Error(data.message || '请求失败');
            }
        } catch (error) {
            console.error('[API Error] 请求失败:', {
                endpoint,
                error: error,
                message: error.message,
                stack: error.stack
            });
            throw error;
        }
    },

    // 用户相关API
    user: {
        async getInfo() {
            console.log('[User API] 获取用户信息');
            return apiService.request(API_ENDPOINTS.USER.INFO);
        },
        async getPrompts() {
            console.log('[User API] 获取用户提示词');
            return apiService.request(API_ENDPOINTS.USER.PROMPTS);
        },
        async getProjects() {
            console.log('[User API] 获取用户项目列表');
            return apiService.request(API_ENDPOINTS.USER.PROJECTS);
        },
        async uploadAvatar(file) {
            console.log('[User API] 上传头像:', { 
                fileName: file.name, 
                fileSize: file.size,
                fileType: file.type 
            });
            
            // 使用专门的上传函数
            return uploadFile(API_ENDPOINTS.USER.UPLOAD_AVATAR, file, {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    console.log('上传进度:', percentCompleted);
                }
            });
        },
        async updateProfile(data) {
            console.log('[User API] 更新用户资料:', data);
            return apiService.request(API_ENDPOINTS.USER.UPDATE, {
                method: 'POST',
                body: JSON.stringify(data)
            });
        }
    },

    // 项目相关API
    project: {
        async getDetail(id) {
            console.log('[Project API] 获取项目详情:', { id });
            return apiService.request(API_ENDPOINTS.PROJECT.DETAIL(id));
        },
        async getWorkspaces(id) {
            return apiService.request(API_ENDPOINTS.PROJECT.PROJECT_WORKSPACES(id));
        },
        async getPrompts(id) {
            return apiService.request(API_ENDPOINTS.PROJECT.PROMPTS(id));
        },
        async delete(id) {
            console.log('[Project API] 删除项目:', { id });
            return apiService.request(API_ENDPOINTS.PROJECT.DELETE(id), { method: 'DELETE' });
        },
        async create(projectData) {
            console.log('[Project API] 创建项目:', projectData);
            return apiService.request(API_ENDPOINTS.PROJECT.CREATE, {
                method: 'POST',
                body: JSON.stringify(projectData)
            });
        },
        async update(projectData) {
            console.log('[Project API] 更新项目:', projectData);
            return apiService.request(API_ENDPOINTS.PROJECT.UPDATE, {
                method: 'PUT',
                body: JSON.stringify(projectData)
            });
        }
    },

    // API密钥相关API
    apiKey: {
        async getList() {
            return apiService.request(API_ENDPOINTS.API_KEY.LIST);
        },
        async generate(description) {
            return apiService.request(API_ENDPOINTS.API_KEY.GENERATE, {
                method: 'POST',
                body: JSON.stringify({ description })
            });
        },
        async disable(keyId) {
            return apiService.request(API_ENDPOINTS.API_KEY.DISABLE(keyId), {
                method: 'POST'
            });
        }
    },

    // 个人资料相关API
    profile: {
        async update(data) {
            return apiService.request(API_ENDPOINTS.PROFILE.UPDATE, {
                method: 'POST',
                body: JSON.stringify(data)
            });
        }
    },

    // 添加认证相关服务
    auth: {
        async login(credentials) {
            return apiService.request(API_ENDPOINTS.USER.LOGIN, {
                method: 'POST',
                body: JSON.stringify(credentials)
            });
        },
        async register(userData) {
            return apiService.request(API_ENDPOINTS.USER.REGISTER, {
                method: 'POST',
                body: JSON.stringify(userData)
            });
        }
    },

    // 博客相关API
    blog: {
        getList() {
            return apiService.request(API_ENDPOINTS.BLOG.LIST);
        },
        getDetail(id) {
            return apiService.request(API_ENDPOINTS.BLOG.DETAIL(id));
        },
        like(id) {
            return apiService.request(API_ENDPOINTS.BLOG.LIKE(id), {
                method: 'POST'
            });
        }
    }
};

export default apiService;
