<template>
  <div class="alert alert-danger" role="alert">
    错误: {{ message }}
  </div>
</template>

<script>
export default {
  name: 'ErrorAlert',
  props: {
    message: {
      type: String,
      required: true
    }
  }
}
</script>
