<template>
  <div class="privacy-page">
    <NavBar />
    <div class="container mt-5 pt-4">
      <div class="content-wrapper">
        <h1>隐私政策</h1>
        <p class="update-info">最后更新时间：2024年10月29日</p>
        
        <div class="privacy-content">
          <section class="mb-4">
            <h2>1. 信息收集</h2>
            <div class="warning">
              <p><strong>请注意：</strong>在使用我们的服务之前，请仔细阅读本隐私政策。继续使用本服务即表示您同意我们按照本政策收集和使用您的信息。</p>
            </div>
            <ul>
              <li>注册信息（邮箱、用户名等）</li>
              <li>使用记录（提示词内容、使用时间等）</li>
              <li>设备信息（操作系统、浏览器类型等）</li>
            </ul>
          </section>

          <section class="mb-4">
            <h2>2. 信息使用</h2>
            <ul>
              <li>提供核心服务功能</li>
              <li>改进用户体验</li>
              <li>发送服务通知</li>
              <li>提供技术支持</li>
            </ul>
          </section>

          <section class="mb-4">
            <h2>3. 信息保护</h2>
            <div class="data-protection">
              <p><strong>数据安全措施：</strong></p>
              <ul>
                <li>采用行业标准的加密技术保护数据传输和存储</li>
                <li>定期进行安全评估和漏洞扫描</li>
                <li>严格的员工访问权限控制和审计机制</li>
                <li>数据中心采用多重备份机制</li>
              </ul>
            </div>
            <p>我们定期评估和更新安全措施。</p>
          </section>

          <section class="mb-4">
            <h2>4. 信息共享</h2>
            <p>除以下情况外，我们不会分享您的信息：</p>
            <ul>
              <li>获得您的明确授权</li>
              <li>法律法规要求</li>
              <li>保护我们的合法权益</li>
            </ul>
          </section>

          <section class="mb-4">
            <h2>5. 用户权利</h2>
            <ul>
              <li>访问和导出数据</li>
              <li>更正或删除信息</li>
              <li>注销账号</li>
            </ul>
          </section>

          <section class="mb-4">
            <h2>6. Cookie 使用</h2>
            <p>我们使用 Cookie 改善用户体验。</p>
            <p>您可以通过浏览器设置管理 Cookie。</p>
          </section>

          <section class="mb-4">
            <h2>7. 政策更新</h2>
            <p>我们可能会更新本隐私政策。</p>
            <p>重大变更会通过邮件或网站通知。</p>
          </section>

          <section class="contact-info">
            <h2>联系我们</h2>
            <p>如果您对我们的隐私政策有任何疑问、意见或投诉，请通过以下方式联系我们：</p>
            <div class="info-box">
              <ul>
                <li>隐私保护官邮箱：privacy@cursorai.online</li>
                <li>客服电话：+8615972198218</li>
                <li>公司地址：武汉市东湖新技术开发区光谷大道</li>
              </ul>
            </div>
            <p>我们承诺在收到您的问题后的3个工作日内回复。</p>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from './NavBar.vue'

export default {
  name: 'PrivacyPage',
  components: {
    NavBar
  },
  // ... 其他代码保持不变 ...
}
</script>

<style scoped>
.privacy-page {
  min-height: 100vh;
  background: var(--bg-primary);
  color: var(--text-primary);
  padding-top: 60px;
}

.container {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.content-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1rem;
}

h1 {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: left;
}

h2 {
  color: var(--secondary-color);
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

p {
  color: var(--text-secondary);
  line-height: 1.5;
  margin-bottom: 0.75rem;
  font-size: 0.95rem;
}

section {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 1rem;
  text-align: left;
}

ul {
  color: var(--text-secondary);
  padding-left: 1.25rem;
  margin-bottom: 0.75rem;
}

li {
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.data-protection {
  background: rgba(124, 58, 237, 0.1);
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem 0;
}

.warning {
  background: rgba(236, 72, 153, 0.1);
  border-left: 3px solid var(--secondary-color);
  padding: 1rem;
  margin: 1rem 0;
}

.info-box {
  background: var(--card-bg);
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0.5rem 0;
}

.update-info {
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin-bottom: 1rem;
}

.contact-info {
  background: rgba(124, 58, 237, 0.1);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;
}

.contact-info a {
  color: var(--primary-color);
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}
</style> 