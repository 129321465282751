export const Theme = {
  DARK: 'dark',
  LIGHT: 'light'
}

export default {
  // 获取当前主题
  getCurrentTheme() {
    return localStorage.getItem('theme') || Theme.DARK
  },

  // 设置主题
  setTheme(theme) {
    localStorage.setItem('theme', theme)
    document.documentElement.setAttribute('data-theme', theme)
    
    // 更新 meta theme-color
    const metaThemeColor = document.querySelector('meta[name="theme-color"]')
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', theme === Theme.DARK ? '#13111C' : '#ffffff')
    }
  },

  // 切换主题
  toggleTheme() {
    const currentTheme = this.getCurrentTheme()
    const newTheme = currentTheme === Theme.DARK ? Theme.LIGHT : Theme.DARK
    this.setTheme(newTheme)
    return newTheme
  }
} 