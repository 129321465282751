<template>
  <div class="register-page">
    <div class="container">
      <div class="text-center mb-4">
        <router-link to="/" class="d-inline-block">
          <img src="@/assets/app-logo.svg" alt="CursorAI Logo" height="40">
        </router-link>
      </div>
      
      <div class="register-container">
        <h1 class="text-center mb-4">注册 Cursor</h1>
        
        <div class="register-box">
          <div v-if="errors.submit" class="alert alert-danger mb-3">
            {{ errors.submit }}
          </div>

          <form @submit.prevent="handleSubmit" id="registerForm">
            <!-- 用户名 -->
            <div class="mb-3">
              <label class="form-label text-start w-100" for="username">用户名</label>
              <input 
                type="text" 
                class="form-control"
                id="username"
                name="username"
                :class="{ 'is-invalid': errors.username }"
                v-model="form.username"
                placeholder="请输入用户名"
                required
                autocomplete="username"
              >
              <div class="invalid-feedback" v-if="errors.username">
                {{ errors.username }}
              </div>
            </div>

            <!-- 邮箱和验证码 -->
            <div class="mb-3">
              <label class="form-label text-start w-100" for="email">邮箱地址</label>
              <div class="row g-2">
                <div class="col">
                  <input 
                    type="email" 
                    class="form-control"
                    id="email"
                    name="email"
                    :class="{ 'is-invalid': errors.email }"
                    v-model="form.email"
                    placeholder="请输入邮箱"
                    required
                    autocomplete="email"
                  >
                  <div class="invalid-feedback" v-if="errors.email">
                    {{ errors.email }}
                  </div>
                </div>
                <div class="col-auto">
                  <button 
                    type="button" 
                    class="btn btn-outline-light h-100" 
                    @click="sendVerifyCode"
                    :disabled="isCodeSending || cooldown > 0"
                  >
                    {{ cooldown > 0 ? `${cooldown}秒` : '获取验证码' }}
                  </button>
                </div>
              </div>
            </div>
            
            <!-- 验证码 -->
            <div class="mb-3">
              <input 
                type="text" 
                class="form-control"
                id="verifyCode"
                name="verifyCode"
                :class="{ 'is-invalid': errors.verifyCode }"
                v-model="form.verifyCode"
                placeholder="请输入验证码"
                required
                maxlength="6"
                autocomplete="off"
              >
              <div class="invalid-feedback" v-if="errors.verifyCode">
                {{ errors.verifyCode }}
              </div>
            </div>

            <!-- 密码 -->
            <div class="mb-3">
              <label class="form-label text-start w-100" for="password">密码</label>
              <input 
                type="password" 
                class="form-control"
                id="password"
                name="password"
                :class="{ 'is-invalid': errors.password }"
                v-model="form.password"
                placeholder="请输入密码"
                required
                autocomplete="new-password"
              >
              <div class="invalid-feedback" v-if="errors.password">
                {{ errors.password }}
              </div>
            </div>

            <!-- 确认密码 -->
            <div class="mb-4">
              <label class="form-label text-start w-100" for="confirmPassword">确认密码</label>
              <input 
                type="password" 
                class="form-control"
                id="confirmPassword"
                name="confirmPassword"
                :class="{ 'is-invalid': errors.confirmPassword }"
                v-model="form.confirmPassword"
                placeholder="请再次输入密码"
                required
                autocomplete="new-password"
              >
              <div class="invalid-feedback" v-if="errors.confirmPassword">
                {{ errors.confirmPassword }}
              </div>
            </div>

            <button 
              type="submit" 
              class="btn btn-primary w-100"
              :disabled="isSubmitting"
            >
              {{ isSubmitting ? '注册中...' : '注册' }}
            </button>
          </form>
        </div>

        <div class="login-hint">
          已有账号？
          <router-link to="/login">立即登录</router-link>
        </div>
      </div>

      <div class="footer-links text-center">
        <router-link to="/terms" class="mx-2">服务条款</router-link>
        <router-link to="/privacy" class="mx-2">隐私政策</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from '@/services/api'

export default {
  name: 'RegisterPage',
  data() {
    return {
      form: {
        username: '',
        email: this.$route.query.email || '',
        verifyCode: '',
        password: '',
        confirmPassword: ''
      },
      errors: {},
      isSubmitting: false,
      isCodeSending: false,
      cooldown: 0,
    }
  },
  created() {
    // 从路由参数中获取 email
    const emailFromQuery = this.$route.query.email
    if (emailFromQuery) {
      this.form.email = emailFromQuery
    }
  },
  methods: {
    // 添加发送验证码方法
    async sendVerifyCode() {
      if (!this.form.email) {
        this.errors.email = '请输入邮箱';
        return;
      }
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.form.email)) {
        this.errors.email = '请输入有效的邮箱地址';
        return;
      }

      this.isCodeSending = true;
      try {
        await apiService.request('/api/email/verify/send', {
          method: 'POST',
          body: JSON.stringify({
            email: this.form.email
          })
        });

        // 开始倒计时
        this.cooldown = 60;
        const timer = setInterval(() => {
          this.cooldown--;
          if (this.cooldown <= 0) {
            clearInterval(timer);
          }
        }, 1000);

      } catch (error) {
        this.errors.email = error.message || '发送验证码失败';
      } finally {
        this.isCodeSending = false;
      }
    },

    // 修改注册提交方法，添加验证码
    async handleSubmit() {
      if (!this.validateForm()) return
      
      this.isSubmitting = true
      try {
        console.log('开始注册请求，参数:', {
          username: this.form.username,
          email: this.form.email,
          password: this.form.password,
          code: this.form.verifyCode  // 添加验证码
        })

        const response = await apiService.auth.register({
          username: this.form.username,
          email: this.form.email,
          password: this.form.password,
          code: this.form.verifyCode  // 添加验证码
        })

        const data = response.data
        
        console.log('注册请求响应数据:', data)
        
        if (response.error_code === 0) {
          console.log('注册成功')
          localStorage.setItem('token', data.token)
          localStorage.setItem('username', data.username)
          this.$router.push('/profile')
        } else {
          console.log('注册返回非0状态:', data)
          this.errors.submit = response.message || '注册失败，请稍后重试'
        }
      } catch (error) {
        console.error('注册失败:', error)
        this.errors.submit = '网络连接异常，请检查网络后重试'
      } finally {
        this.isSubmitting = false
      }
    },

    // 修改表单验证，添加验证码验证
    validateForm() {
      this.errors = {}
      
      // 用户名验证
      if (!this.form.username) {
        this.errors.username = '请输入用户名'
      } else if (this.form.username.length < 2) {
        this.errors.username = '用户名长度不能少于2位'
      }
      
      // 邮箱验证
      if (!this.form.email) {
        this.errors.email = '请输入邮箱'
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.form.email)) {
        this.errors.email = '请输入有效的邮箱地址'
      }
      
      // 密码验证
      if (!this.form.password) {
        this.errors.password = '请输入密码'
      } else if (this.form.password.length < 6) {
        this.errors.password = '密码长度不能少于6位'
      }
      
      // 确认密码验证
      if (!this.form.confirmPassword) {
        this.errors.confirmPassword = '请确认密码'
      } else if (this.form.confirmPassword !== this.form.password) {
        this.errors.confirmPassword = '两次输入的密码不一致'
      }
      
      // 验证码验证
      if (!this.form.verifyCode) {
        this.errors.verifyCode = '请输入验证码'
      } else if (!/^\d{6}$/.test(this.form.verifyCode)) {
        this.errors.verifyCode = '请输入6位数字验证码'
      }
      
      return Object.keys(this.errors).length === 0
    }
  }
}
</script>

<style scoped>
.register-page {
  min-height: 100vh;
  background: var(--bg-primary);
  color: var(--text-primary);
  padding: 2rem 0;
}

.register-container {
  max-width: 400px;
  margin: 0 auto;
}

.register-box {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 0.5rem;
  padding: 1.5rem;
  backdrop-filter: blur(10px);
  margin-bottom: 1rem;
}

.form-label {
  color: var(--text-primary);
  font-weight: 500;
}

.form-control {
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  padding: 0.8rem 1rem;
}

.form-control:focus {
  background: var(--bg-secondary);
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.2rem rgba(124, 58, 237, 0.25);
  color: var(--text-primary);
}

.form-control::placeholder {
  color: var(--text-secondary);
}

.btn-primary {
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  border: none;
  padding: 0.8rem;
  font-weight: 500;
}

.btn-primary:disabled {
  background: var(--card-bg);
}

.btn-outline-light {
  border-color: var(--border-color);
  color: var(--text-primary);
}

.btn-outline-light:hover:not(:disabled) {
  background: var(--bg-secondary);
  border-color: var(--primary-color);
  color: var(--text-primary);
}

.btn-outline-light:disabled {
  color: var(--text-secondary);
  border-color: var(--border-color);
}

.login-hint {
  text-align: center;
  padding: 1rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 0.5rem;
}

.login-hint a {
  color: var(--primary-color);
  text-decoration: none;
}

.login-hint a:hover {
  text-decoration: underline;
}

.footer-links {
  margin-top: 2rem;
}

.footer-links a {
  color: var(--text-secondary);
  text-decoration: none;
  font-size: 0.9rem;
}

.footer-links a:hover {
  color: var(--text-primary);
}

.alert-danger {
  background: rgba(220, 53, 69, 0.1);
  border: 1px solid rgba(220, 53, 69, 0.2);
  color: #dc3545;
}

.invalid-feedback {
  display: block;
  color: var(--error-color);
}

/* 添加移动端适配 */
@media (max-width: 576px) {
  .register-page {
    padding: 1rem 0;
  }

  .register-container {
    max-width: 100%;
    padding: 0 1rem;
  }

  .register-box {
    padding: 1.25rem;
  }

  h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .form-control {
    padding: 0.6rem 0.8rem;
  }

  .btn-primary {
    padding: 0.6rem;
  }

  /* 验证码按钮样式调整 */
  .btn-outline-light {
    padding: 0.6rem 0.8rem;
    white-space: nowrap;
  }
}
</style>
