<template>
  <nav class="navbar navbar-expand-lg" :class="{'navbar-light': isLightTheme, 'navbar-dark': !isLightTheme}">
    <div class="container">
      <!-- Logo -->
      <router-link class="navbar-brand d-flex align-items-center" to="/">
        <img src="@/assets/app-logo.svg" alt="CursorAI Logo" height="32">
        <span class="ms-2 d-none d-sm-inline">CursorAI</span>
      </router-link>

      <!-- 移动端菜单按钮 -->
      <button 
        class="navbar-toggler" 
        type="button" 
        @click="toggleMenu"
        aria-label="Toggle navigation"
      >
        <i class="bi" :class="isMenuOpen ? 'bi-x-lg' : 'bi-list'"></i>
      </button>

      <!-- 导航内容 -->
      <div 
        class="navbar-collapse" 
        :class="{ show: isMenuOpen }"
      >
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link to="/public" class="nav-link" @click="closeMenu">浏览提示词</router-link>
          </li>
        </ul>

        <div class="d-flex align-items-center nav-right">
          <!-- 主题换按钮 -->
          <button 
            class="btn btn-icon theme-btn" 
            @click="toggleTheme"
            :title="isLightTheme ? '切换到深色模式' : '切换到浅色模式'"
          >
            <i class="bi" :class="isLightTheme ? 'bi-moon-fill' : 'bi-sun-fill'"></i>
          </button>
          
          <!-- 未登录状态 -->
          <template v-if="!isLoggedIn">
            <router-link to="/login" class="btn btn-outline-primary me-2" @click="closeMenu">
              登录
            </router-link>
            <router-link to="/register" class="btn btn-primary" @click="closeMenu">
              免费注册
            </router-link>
          </template>

          <!-- 已登录状态 -->
          <template v-else>
            <div class="dropdown">
              <div 
                class="user-menu d-flex align-items-center"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div class="avatar-wrapper">
                  <img 
                    v-if="userAvatar && userAvatar !== 'undefined'"
                    :src="userAvatar" 
                    alt="用户头像"
                    class="user-avatar"
                    @error="handleAvatarError"
                  >
                  <div v-else class="default-avatar">
                    {{ username ? username.charAt(0).toUpperCase() : 'U' }}
                  </div>
                </div>
              </div>
              
              <ul class="dropdown-menu dropdown-menu-end">
                <li class="dropdown-header">
                  <span class="username">{{ username }}</span>
                </li>
                <li><hr class="dropdown-divider"></li>
                <li>
                  <router-link to="/profile" class="dropdown-item" @click="closeMenu">
                    <i class="bi bi-person me-2"></i>个人中心
                  </router-link>
                </li>
                <li>
                  <router-link to="/settings" class="dropdown-item" @click="closeMenu">
                    <i class="bi bi-gear me-2"></i>设置
                  </router-link>
                </li>
                <li><hr class="dropdown-divider"></li>
                <li>
                  <a class="dropdown-item" href="#" @click.prevent="handleLogout">
                    <i class="bi bi-box-arrow-right me-2"></i>退出登录
                  </a>
                </li>
              </ul>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- 遮罩层 -->
    <div 
      v-if="isMenuOpen" 
      class="navbar-backdrop"
      @click="closeMenu"
    ></div>
  </nav>
</template>

<script>
import themeUtil, { Theme } from '@/utils/theme'
import { Dropdown } from 'bootstrap'

export default {
  name: 'NavBar',
  data() {
    return {
      isLightTheme: themeUtil.getCurrentTheme() === Theme.LIGHT,
      isLoggedIn: false,
      username: '',
      userAvatar: localStorage.getItem('userAvatar'),
      isMenuOpen: false
    }
  },
  created() {
    this.checkLoginStatus()
    // 添加事件监听
    window.addEventListener('storage', this.handleStorageChange)
    // 添加头像更新事件监听
    window.addEventListener('avatar-updated', this.handleAvatarUpdate)
  },
  beforeUnmount() {
    // 移除事件监听
    window.removeEventListener('storage', this.handleStorageChange)
    window.removeEventListener('avatar-updated', this.handleAvatarUpdate)
    // 组件销毁时确保恢复body滚动
    document.body.style.overflow = '';
  },
  mounted() {
    // 初始化所有下拉菜单
    const dropdownElementList = document.querySelectorAll('.dropdown-toggle')
    dropdownElementList.forEach(dropdownToggle => {
      new Dropdown(dropdownToggle)
    })
  },
  methods: {
    toggleTheme() {
      const newTheme = themeUtil.toggleTheme()
      this.isLightTheme = newTheme === Theme.LIGHT
    },
    checkLoginStatus() {
      const token = localStorage.getItem('token')
      const username = localStorage.getItem('username')
      const avatar = localStorage.getItem('userAvatar')
      this.isLoggedIn = !!token
      this.username = username || ''
      this.userAvatar = avatar || ''
    },
    handleLogout() {
      localStorage.removeItem('token')
      localStorage.removeItem('username')
      localStorage.removeItem('userAvatar')
      this.isLoggedIn = false
      this.username = ''
      this.userAvatar = ''
      
      // 触发一个自定义事件通知其他组件
      window.dispatchEvent(new Event('logout'))
      
      // 强制刷新路由
      if (this.$route.path === '/') {
        // 如果已经在首页，强制刷新组件
        this.$router.go(0)
      } else {
        // 如果不在首页，跳转到首页
        this.$router.push('/')
      }
    },
    handleAvatarUpdate(event) {
      console.log('[NavBar] 收到头像更新事件:', event.detail);
      this.userAvatar = event.detail.avatarUrl;
    },
    handleStorageChange(event) {
      if (event.key === 'token' || event.key === 'username' || event.key === 'userAvatar') {
        this.checkLoginStatus()
      }
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      // 切换body滚动
      document.body.style.overflow = this.isMenuOpen ? 'hidden' : '';
    },
    closeMenu() {
      if (this.isMenuOpen) {
        this.isMenuOpen = false;
        document.body.style.overflow = '';
      }
    },
    handleAvatarError(e) {
      // 图片加载失败时显示默认头像
      e.target.style.display = 'none';
      this.userAvatar = null;
    },
  },
  watch: {
    $route() {
      this.checkLoginStatus();
      this.closeMenu(); // 路由变化时关闭菜单
    }
  }
}
</script>

<style scoped>
.navbar {
  height: 60px;
  background: var(--navbar-bg);
  border-bottom: 1px solid var(--border-color);
  padding: 0.5rem 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.navbar-brand {
  text-decoration: none;
  color: var(--text-primary);
}

.navbar-brand:hover {
  color: var(--text-primary);
}

.nav-link {
  color: var(--text-secondary);
  text-decoration: none;
  transition: color 0.3s;
  padding: 0.5rem 1rem;
}

.nav-link:hover {
  color: var(--primary-color);
}

.btn-icon {
  background: transparent;
  border: none;
  color: var(--text-secondary);
  padding: 0.5rem;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.btn-icon:hover {
  background: var(--card-bg);
  color: var(--primary-color);
}

.btn-icon i {
  font-size: 1.2rem;
}

/* 移动端菜单按钮式 */
.navbar-toggler {
  width: 40px;
  height: 40px;
  padding: 0;
  display: none;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  color: var(--text-primary);
  transition: all 0.3s ease;
  position: relative;
  z-index: 1001;
}

.navbar-toggler:hover {
  background: var(--bg-secondary);
  border-radius: 50%;
}

.navbar-toggler i {
  font-size: 1.5rem;
}

/* 用户菜单样式 */
.user-menu {
  padding: 0.25rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.user-menu:hover {
  background: var(--bg-secondary);
}

.avatar-wrapper {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-secondary);
}

.user-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-placeholder {
  font-size: 24px;
  color: var(--text-secondary);
}

/* 下拉菜单样式调整 */
.dropdown-header {
  padding: 0.5rem 1rem;
  color: var(--text-primary);
  font-weight: 500;
  font-size: 0.9rem;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  min-width: 200px;
  padding: 0.5rem 0;
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 0.5rem;
  z-index: 1000;
}

.dropdown-item {
  padding: 0.5rem 1rem;
  color: var(--text-primary);
  display: flex;
  align-items: center;
}

.dropdown-item i {
  width: 20px;
  font-size: 1rem;
}

.dropdown-item:hover {
  background: var(--bg-secondary);
  color: var(--primary-color);
}

.dropdown-divider {
  margin: 0.25rem 0;
  border-color: var(--border-color);
}

/* 移动端适配 */
@media (max-width: 991px) {
  .navbar {
    height: 60px;
    min-height: 60px;
    padding: 0;
  }

  .navbar-collapse {
    display: none;
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    height: calc(100vh - 60px);
    background: var(--navbar-bg);
    padding: 1rem;
    border-top: 1px solid var(--border-color);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    overflow-y: auto;
    z-index: 999;
  }

  .navbar-collapse.show {
    display: block;
  }

  /* 导航项样式 */
  .nav-item {
    border-bottom: 1px solid var(--border-color);
  }

  .nav-link {
    padding: 1rem;
    width: 100%;
  }

  /* 按钮样式 */
  .nav-right {
    margin-top: 1rem;
    flex-direction: column;
    width: 100%;
  }

  .nav-right .btn {
    width: 100%;
    margin: 0.5rem 0;
  }

  /* 用户菜单样式 */
  .user-menu {
    width: 100%;
    border-radius: 0.5rem;
    padding: 0.75rem;
    background: var(--bg-secondary);
  }

  .dropdown {
    width: 100%;
  }

  .dropdown-menu {
    position: static;
    width: 100%;
    margin-top: 0.5rem;
    box-shadow: none;
  }

  /* 遮罩层样式 */
  .navbar-backdrop {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 998;
    backdrop-filter: blur(4px);
  }

  .theme-btn {
    width: 100%;
    padding: 0.75rem;
    justify-content: flex-start;
    border-radius: 0.5rem;
  }

  .theme-btn:hover {
    background: var(--bg-secondary);
  }

  .theme-btn i {
    font-size: 1.2rem;
  }

  .navbar .navbar-toggler {
    display: flex !important;
  }
}

/* 平板适配 */
@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    max-width: 960px;
  }

  .nav-link {
    padding: 0.5rem;
  }
}

/* 优化触摸目标 */
@media (pointer: coarse) {
  .nav-link,
  .dropdown-item,
  .btn {
    min-height: 44px;
    display: flex;
    align-items: center;
  }
}

/* 修改容器样式 */
.container {
  max-width: 980px !important;
  margin: 0 auto;
  padding: 0 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
</style>
