是<template>
  <div class="projects-page">
    <NavBar 
      :is-logged-in="true"
      :username="username"
      @logout="handleLogout"
    />

    <main class="container py-4">
      <div class="content-wrapper">
        <div class="d-flex justify-content-between align-items-start mb-4">
          <div class="text-start">
            <h2 class="text-dark mb-1">我的项目</h2>
            <p class="text-muted mb-0">共 {{ projects.length }} 个项目</p>
          </div>
          <button class="btn btn-success d-flex align-items-center gap-2" @click="createProject">
            <i class="bi bi-plus-lg"></i>
            <span>创建项目</span>
          </button>
        </div>

        <div class="row">
          <div class="col-12 mb-4" v-for="project in projects" :key="project.id">
            <div class="card project-card">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-start mb-3">
                  <div class="text-start">
                    <h5 class="card-title mb-2 text-dark fw-bold text-start">{{ project.name }}</h5>
                    <div class="project-meta mb-2">
                      <span class="badge bg-primary me-2">{{ project.language }}</span>
                      <span class="text-muted small">
                        <i class="bi bi-clock me-1"></i>
                        {{ formatDate(project.create_time) }}
                      </span>
                    </div>
                  </div>
                  <div class="btn-group">
                    <button class="btn btn-sm btn-outline-primary d-flex align-items-center gap-1" 
                            @click="openProject(project.id)">
                      <i class="bi bi-folder2-open"></i>
                      <span>打开</span>
                    </button>
                    <button class="btn btn-sm btn-outline-secondary d-flex align-items-center gap-1" 
                            @click="editProject(project)">
                      <i class="bi bi-pencil"></i>
                      <span>修改</span>
                    </button>
                    <button class="btn btn-sm btn-outline-danger d-flex align-items-center gap-1" 
                            @click="deleteProject(project.id)">
                      <i class="bi bi-trash"></i>
                      <span>删除</span>
                    </button>
                  </div>
                </div>
                
                <p class="card-text text-dark mb-3 text-start" v-if="project.desc">{{ project.desc }}</p>
                <p class="card-text text-muted fst-italic mb-3 text-start" v-else>暂无描述</p>

                <div class="project-footer d-flex justify-content-between align-items-center">
                  <div class="text-muted small">
                    <span class="me-3">
                      <i class="bi bi-calendar-check me-1"></i>
                      最后更新: {{ formatDate(project.update_time) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 无项目时显示的提示 -->
        <div v-if="!isLoading && projects.length === 0" class="text-center py-5">
          <i class="bi bi-folder2 display-1 text-muted"></i>
          <p class="mt-3 text-muted">暂无项目，点击右上角按钮创建新项目</p>
        </div>

        <!-- 加载中状态 -->
        <div v-if="isLoading" class="text-center py-5">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">加载中...</span>
          </div>
        </div>
      </div>
    </main>

    <!-- 添加创建项目对话框 -->
    <CreateProjectDialog 
      ref="createProjectDialog"
      :is-edit="true"
      :edit-project="currentEditProject"
      @project-created="fetchProjects"
      @project-updated="fetchProjects"
    />
  </div>
</template>

<script>
import CreateProjectDialog from './CreateProjectDialog.vue'
import { formatDate } from '@/utils/dateUtils' // 假设我们有一个日期格式化工具
import NavBar from './NavBar.vue'
import { getProjectStatus, getStatusClass } from '@/utils/projectUtils'

export default {
  name: 'ProjectsPage',
  components: {
    CreateProjectDialog,
    NavBar
  },
  data() {
    return {
      projects: [],
      isLoading: false,
      error: null,
      currentEditProject: null,  // 当前正在编辑的项目
      username: localStorage.getItem('username')
    }
  },
  async created() {
    await this.fetchProjects()
  },
  methods: {
    async fetchProjects() {
      this.isLoading = true
      try {
        const response = await fetch('/api/user/projects', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        const data = await response.json()
        if (data.error_code === 0) {
          if (data.data && Array.isArray(data.data)) {
            this.projects = data.data
          } else {
            this.projects = []
            console.warn('未找到项目数据或数据格式不正确:', data)
          }
          console.log('获取到的项目数据:', JSON.stringify(this.projects, null, 2))
          if (this.projects.length > 0) {
            console.log('第一个项目的结构:', JSON.stringify(this.projects[0], null, 2))
          }
        } else {
          this.error = data.message
          this.projects = []
        }
      } catch (error) {
        console.error('获取项目列表失败:', error)
        this.error = '加载失败，请稍后重试'
        this.projects = []
      } finally {
        this.isLoading = false
      }
    },
    formatDate(dateString) {
      if (!dateString) return '未知日期'
      return formatDate(dateString)
    },
    createProject() {
      this.$refs.createProjectDialog.showDialog()
    },
    openProject(projectId) {
      this.$router.push(`/project/${projectId}`)
    },
    editProject(project) {
      this.currentEditProject = project;
      this.$refs.createProjectDialog.showDialog();
    },
    async deleteProject(projectId) {
      if (!confirm('确定要删除这个项目吗？此操作不可恢复。')) {
        return
      }
      
      try {
        console.log('开始删除项目:', projectId)
        const response = await fetch(`/api/project/delete?id=${projectId}`, {  // 将 ID 放在 query 参数中
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        
        console.log('收到服务器响应:', response.status)
        const data = await response.json()
        console.log('响应数据:', data)
        
        if (data.error_code === 0) {
          console.log('项目删除成功')
          await this.fetchProjects()  // 刷新项目列表
        } else {
          console.error('删除失败:', data.message)
          alert(data.message || '删除失败')
        }
      } catch (error) {
        console.error('删除项目失败:', error)
        console.error('错误详情:', {
          name: error.name,
          message: error.message,
          stack: error.stack
        })
        alert('操作失败，请稍后重试')
      }
    },
    handleLogout() {
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      this.$router.push('/');
    },
    getProjectStatus,
    getStatusClass
  }
}
</script>

<style scoped>
.projects-page {
  min-height: 100vh;
  background-color: #f8f9fa;
}

.content-wrapper {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
}

.project-card {
  transition: all 0.2s ease-in-out;
  border: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.project-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.card-body {
  padding: 1.5rem;
}

.card-title {
  font-size: 1.25rem;
  line-height: 1.4;
  text-align: left;
}

.project-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.badge {
  font-weight: 500;
  padding: 0.5em 0.8em;
}

.btn-group .btn {
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
}

.project-footer {
  padding-top: 1rem;
  border-top: 1px solid rgba(0,0,0,0.05);
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

/* 确保所有文本都是深色的 */
.text-dark {
  color: #2c3e50 !important;
}

.text-muted {
  color: #6c757d !important;
}

/* 添加描述文本的样式 */
.card-text {
  text-align: left;
  word-break: break-word;
}

h2 {
  text-align: left;
}
</style>
