<template>
  <router-view></router-view>
</template>

<script>
import themeUtil from '@/utils/theme'

export default {
  name: 'App',
  mounted() {
    // 初始化主题
    themeUtil.setTheme(themeUtil.getCurrentTheme())
    
    // 添加结构化数据
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "SoftwareApplication",
      "name": "Cursor 提示词助手",
      "applicationCategory": "DeveloperApplication",
      "operatingSystem": "Windows",
      "offers": {
        "@type": "Offer",
        "price": "0",
        "priceCurrency": "CNY"
      },
      "description": "智能记录和管理您的 Cursor AI 提示词，提高开发效率。自动记录、智能分类、一键分享。",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.8",
        "ratingCount": "150"
      }
    }

    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.text = JSON.stringify(structuredData)
    document.head.appendChild(script)
  }
}
</script>

<style>
:root[data-theme="light"] {
  --bg-primary: #ffffff;
  --bg-secondary: #f8f9fa;
  --text-primary: #1a1b1e;
  --text-secondary: #4a5568;
  --border-color: #e2e8f0;
  --primary-color: #7C3AED;
  --secondary-color: #EC4899;
  --card-bg: #ffffff;
  --card-border: #e2e8f0;
  --navbar-bg: #ffffff;
  --navbar-text: #1a1b1e;
}

:root[data-theme="dark"] {
  --bg-primary: #13111C;
  --bg-secondary: #1a1825;
  --text-primary: #ffffff;
  --text-secondary: #a1a1aa;
  --border-color: #2d2b3b;
  --primary-color: #7C3AED;
  --secondary-color: #EC4899;
  --card-bg: #1E1B2E;
  --card-border: #2d2b3b;
  --navbar-bg: #13111C;
  --navbar-text: #ffffff;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: var(--bg-primary);
  color: var(--text-primary);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s, color 0.3s;
}
</style>
