<template>
  <div class="alert alert-info" role="alert">
    暂无数据
  </div>
</template>

<script>
export default {
  name: 'EmptyState'
}
</script>
