export function formatDateTime(timestamp) {
  if (!timestamp) return '';
  
  // 如果包含毫秒，去掉毫秒部分
  const date = new Date(timestamp.split('.')[0]);
  
  return date.toLocaleString('zh-CN', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  });
}

export function getCommandType(type) {
  const types = {
    '1': '通用命令',
    '2': '代码分析',
    '3': '代码生成',
    '4': '源码阅读'
  }
  return types[type] || '未知类型'
}
