<template>
  <div class="modal" tabindex="-1" :class="{ 'd-block': show }">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ isEdit ? '编辑项目' : '创建项目' }}</h5>
          <button 
            type="button" 
            class="btn-close" 
            @click="handleClose"
            :aria-label="'关闭'"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="handleSubmit">
            <div class="mb-3">
              <label for="projectName" class="form-label">项目名称</label>
              <input 
                type="text" 
                class="form-control" 
                id="projectName" 
                v-model="formData.name"
                :class="{ 'is-invalid': errors.name }"
                placeholder="输入项目名称"
              >
              <div class="invalid-feedback">{{ errors.name }}</div>
            </div>
            
            <div class="mb-3">
              <label for="projectDescription" class="form-label">项目描述</label>
              <textarea 
                class="form-control" 
                id="projectDescription" 
                v-model="formData.description"
                :class="{ 'is-invalid': errors.description }"
                rows="3"
                placeholder="输入项目描述（可选）"
              ></textarea>
              <div class="invalid-feedback">{{ errors.description }}</div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button 
            type="button" 
            class="btn btn-outline-secondary" 
            @click="handleClose"
          >
            取消
          </button>
          <button 
            type="button" 
            class="btn btn-primary" 
            @click="handleSubmit"
            :disabled="isSubmitting"
          >
            {{ isSubmitting ? '保存中...' : (isEdit ? '保存' : '创建') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from '@/services/api'

export default {
  name: 'CreateProjectDialog',
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    editProject: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      show: false,
      formData: {
        name: '',
        description: ''
      },
      errors: {},
      isSubmitting: false
    }
  },
  methods: {
    showDialog() {
      this.show = true
      this.resetForm()
      
      if (this.isEdit && this.editProject) {
        console.log('[CreateProjectDialog] 编辑模式，项目数据:', this.editProject)
        this.formData = {
          name: this.editProject.name || '',
          description: this.editProject.desc || ''
        }
      }
    },
    handleClose() {
      this.show = false
      this.resetForm()
      this.$emit('close')
    },
    resetForm() {
      this.formData = {
        name: '',
        description: ''
      }
      this.errors = {}
    },
    async handleSubmit() {
      this.errors = {}
      
      if (!this.formData.name) {
        this.errors.name = '请输入项目名称'
        return
      }
      
      this.isSubmitting = true
      try {
        if (this.isEdit && this.editProject) {
          console.log('[CreateProjectDialog] 更新项目:', {
            id: this.editProject.id,
            name: this.formData.name,
            desc: this.formData.description
          })
          
          await apiService.project.update({
            id: this.editProject.id,
            name: this.formData.name,
            desc: this.formData.description
          })
          this.$emit('project-updated')
        } else {
          console.log('[CreateProjectDialog] 创建新项目:', {
            name: this.formData.name,
            desc: this.formData.description
          })
          await apiService.project.create({
            name: this.formData.name,
            desc: this.formData.description
          })
          this.$emit('project-created')
        }
        this.handleClose()
      } catch (error) {
        console.error('[CreateProjectDialog] 保存项目失败:', error)
        this.errors.submit = error.message
      } finally {
        this.isSubmitting = false
      }
    }
  },
  watch: {
    editProject: {
      handler(newProject) {
        if (this.show && this.isEdit && newProject) {
          console.log('[CreateProjectDialog] editProject 更新:', newProject)
          this.formData = {
            name: newProject.name || '',
            description: newProject.desc || ''
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.75) !important;
}

.modal-content {
  background: var(--card-bg) !important;
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  backdrop-filter: none !important;
}

.modal-header {
  background: var(--card-bg) !important;
  border-bottom: 1px solid var(--border-color);
  padding: 1rem 1.5rem;
}

.modal-body {
  background: var(--card-bg) !important;
  padding: 1.5rem;
}

.modal-footer {
  background: var(--card-bg) !important;
  border-top: 1px solid var(--border-color);
  padding: 1rem 1.5rem;
}

.modal-title {
  color: var(--text-primary);
  font-weight: 600;
}

.form-label {
  color: var(--text-primary);
  font-weight: 500;
}

.form-control {
  background: var(--bg-secondary) !important;
  border: 1px solid var(--border-color);
  color: var(--text-primary) !important;
}

.form-control:focus {
  background: var(--bg-secondary) !important;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.2rem rgba(124, 58, 237, 0.25);
  color: var(--text-primary) !important;
}

.form-control::placeholder {
  color: var(--text-secondary);
}

.btn-close {
  color: var(--text-primary);
  opacity: 0.75;
}

.btn-close:hover {
  opacity: 1;
}

.btn-outline-secondary {
  color: var(--text-secondary);
  border-color: var(--border-color);
}

.btn-outline-secondary:hover {
  background: var(--bg-secondary);
  color: var(--text-primary);
  border-color: var(--text-primary);
}

.invalid-feedback {
  color: var(--error-color);
}

.form-control.is-invalid {
  border-color: var(--error-color);
  background-image: none;
}

.form-control.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
</style>
