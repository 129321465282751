<template>
  <div class="settings-page">
    <NavBar />
    
    <main class="main-content">
      <div class="container py-4">
        <div class="content-wrapper">
          <div class="d-flex align-items-center mb-4">
            <button class="btn btn-outline-primary me-3" @click="goBack">
              <i class="bi bi-arrow-left me-1"></i>返回
            </button>
            <h2 class="mb-0">设置</h2>
          </div>

          <!-- API 密钥管理 -->
          <div class="settings-card">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h5 class="mb-0">API 密钥</h5>
              <button class="btn btn-primary btn-sm" @click="createApiKey">
                创建新密钥
              </button>
            </div>
            <div class="card-body">
              <div v-if="isLoading" class="text-center py-3">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">加载中...</span>
                </div>
              </div>
              
              <div v-else-if="error" class="alert alert-danger">
                {{ error }}
              </div>
              
              <div v-else>
                <div v-if="activeApiKeys.length === 0" class="text-center py-3">
                  <p class="text-muted">暂无 API 密钥</p>
                </div>
                
                <div v-else class="api-keys-list">
                  <div 
                    v-for="key in activeApiKeys" 
                    :key="key.id" 
                    class="api-key-item"
                  >
                    <div class="d-flex flex-column">
                      <div class="key-value mb-2 d-flex align-items-center">
                        <code class="flex-grow-1 text-start">{{ key.key }}</code>
                        <button 
                          class="btn btn-outline-light btn-sm ms-2 flex-shrink-0"
                          @click="copyKey(key.key, $event)"
                          ref="copyBtn"
                          title="复制密钥"
                        >
                          <i :class="['bi', copyingKeys[key.key] ? 'bi-check' : 'bi-clipboard']"></i>
                        </button>
                      </div>
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="small d-flex align-items-center text-muted">
                          <span>{{ key.description || '无描述' }}</span>
                          <span class="mx-2">•</span>
                          <span>创建时间: {{ new Date(key.created_at).toLocaleString() }}</span>
                        </div>
                        <button 
                          class="btn btn-outline-danger btn-sm"
                          @click="deleteApiKey(key.id)"
                        >
                          删除
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- 创建 API 密钥对话框 -->
    <div class="modal" tabindex="-1" :class="{ 'd-block': showCreateKeyDialog }">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">创建 API 密钥</h5>
            <button type="button" class="btn-close" @click="showCreateKeyDialog = false"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="keyName" class="form-label">密钥描述</label>
              <input 
                type="text" 
                class="form-control" 
                id="keyName" 
                v-model="newKeyName"
                placeholder="为你的密钥添加描述"
              >
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showCreateKeyDialog = false">
              取消
            </button>
            <button 
              type="button" 
              class="btn btn-primary" 
              @click="confirmCreateKey"
              :disabled="isCreatingKey"
            >
              {{ isCreatingKey ? '创建中...' : '创建' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from './NavBar.vue'
import apiService from '@/services/api'

export default {
  name: 'SettingsPage',
  components: {
    NavBar
  },
  data() {
    return {
      apiKeys: [],
      isLoading: false,
      error: null,
      showCreateKeyDialog: false,
      newKeyName: '',
      isCreatingKey: false,
      copyingKeys: {}
    }
  },
  created() {
    this.loadApiKeys()
  },
  computed: {
    activeApiKeys() {
      return this.apiKeys.filter(key => key.is_active)
    }
  },
  methods: {
    goBack() {
      if (window.history.length > 1) {
        this.$router.back()
      } else {
        this.$router.push('/')
      }
    },
    async loadApiKeys() {
      this.isLoading = true
      try {
        console.log('开始获取 API 密钥列表...')
        const data = await apiService.apiKey.getList();
        
        if (data.keys) {
          this.apiKeys = data.keys;
        }
      } catch (error) {
        console.error('获取 API 密钥列表出错:', error)
        this.error = '加载失败，请稍后重试'
      } finally {
        this.isLoading = false
      }
    },
    async createApiKey() {
      this.showCreateKeyDialog = true
      this.newKeyName = ''
    },
    async confirmCreateKey() {
      if (!this.newKeyName.trim()) {
        alert('请输入密钥描述')
        return
      }
      
      this.isCreatingKey = true
      try {
        const data = await apiService.apiKey.generate(this.newKeyName);
        
        if (data.error_code === 0) {
          this.showCreateKeyDialog = false
          this.loadApiKeys()
        }
      } catch (error) {
        console.error('创建 API 密钥出错:', error)
        alert('创建失败，请稍后重试')
      } finally {
        this.isCreatingKey = false
      }
    },
    async deleteApiKey(keyId) {
      if (!confirm('确定要删除这个密钥吗？删除后将无法恢复。')) return
      
      try {
        await apiService.apiKey.disable(keyId)
        this.loadApiKeys()
      } catch (error) {
        console.error('删除 API 密钥出错:', error)
        alert('删除失败，请稍后重试')
      }
    },
    async copyKey(key, event) {
      if (this.copyingKeys[key]) return;
      
      try {
        await navigator.clipboard.writeText(key);
        this.copyingKeys[key] = true;
        
        const btn = event.currentTarget;
        if (btn) {
          btn.classList.add('btn-success');
          btn.classList.remove('btn-outline-secondary');
        }
        
        setTimeout(() => {
          this.copyingKeys[key] = false;
          if (btn) {
            btn.classList.remove('btn-success');
            btn.classList.add('btn-outline-secondary');
          }
        }, 1500);
      } catch (err) {
        console.error('复制失败:', err);
        alert('复制失败，请手动复制');
      }
    }
  }
}
</script>

<style scoped>
.settings-page {
  min-height: 100vh;
  background: var(--bg-primary);
  color: var(--text-primary);
}

.main-content {
  padding-top: 60px; /* 为固定导航栏留出空间 */
}

.content-wrapper {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
}

h2 {
  color: var(--text-primary);
  font-weight: 600;
}

.settings-card {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
}

.card-header {
  background: var(--bg-secondary);
  border-bottom: 1px solid var(--border-color);
  padding: 1rem 1.5rem;
}

.card-header h5 {
  color: var(--heading-color);
  font-weight: 500;
}

.card-body {
  padding: 1.5rem;
}

.form-label {
  color: var(--text-primary);
  font-weight: 500;
}

.form-control {
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  padding: 0.8rem 1rem;
}

.form-control:focus {
  background: var(--bg-secondary);
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.2rem rgba(124, 58, 237, 0.25);
  color: var(--text-primary);
}

.form-control::placeholder {
  color: var(--text-secondary);
}

.btn-primary {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
}

.btn-primary:hover {
  background: var(--primary-color);
  opacity: 0.9;
}

.api-keys-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.api-key-item {
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  padding: 1rem;
}

.key-value {
  background: var(--card-bg);
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.key-value code {
  color: var(--text-primary);
  font-family: monospace;
  text-align: left;
  word-break: break-all;
  padding: 0.25rem;
}

.text-muted {
  color: var(--text-secondary) !important;
}

.modal-content {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
}

.modal-header {
  border-bottom: 1px solid var(--border-color);
}

.modal-footer {
  border-top: 1px solid var(--border-color);
}

.modal-title {
  color: var(--heading-color);
}

.btn-close {
  color: var(--text-primary);
}

.alert-danger {
  background: var(--error-bg);
  border: 1px solid var(--error-border);
  color: var(--error-color);
}

.spinner-border {
  color: var(--primary-color);
}

/* 按钮样式 */
.btn-outline-light {
  border-color: var(--border-color);
  color: var(--text-primary);
}

.btn-outline-light:hover {
  background: var(--bg-secondary);
  border-color: var(--primary-color);
  color: var(--text-primary);
}

.btn-secondary {
  background: var(--bg-secondary);
  border-color: var(--border-color);
  color: var(--text-primary);
}

.btn-secondary:hover {
  background: var(--bg-hover);
  border-color: var(--border-hover);
  color: var(--text-primary);
}

.btn-outline-danger {
  color: var(--error-color);
  border-color: var(--error-color);
}

.btn-outline-danger:hover {
  background: var(--error-color);
  color: white;
}

/* 状态标签 */
.text-success {
  color: var(--success-color) !important;
}

.text-danger {
  color: var(--error-color) !important;
}

/* 复制按钮状态 */
.btn-success {
  background: var(--success-color);
  border-color: var(--success-color);
  color: white;
}

.btn-outline-secondary {
  border-color: var(--border-color);
  color: var(--text-secondary);
}

.btn-outline-secondary:hover {
  background: var(--bg-secondary);
  color: var(--text-primary);
}

.btn-outline-primary {
  color: var(--primary-color);
  border-color: var(--primary-color);
  background: transparent;
}

.btn-outline-primary:hover {
  background: var(--primary-color);
  color: white;
}
</style>

