<template>
  <div class="toast-container position-fixed top-0 end-0 p-3">
    <div 
      id="successToast" 
      class="toast align-items-center text-white bg-success border-0" 
      role="alert" 
      aria-live="assertive" 
      aria-atomic="true"
    >
      <div class="d-flex">
        <div class="toast-body">
          {{ message }}
        </div>
        <button 
          type="button" 
          class="btn-close btn-close-white me-2 m-auto" 
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToastMessage',
  data() {
    return {
      message: ''
    }
  },
  methods: {
    show(message, duration = 3000) {
      this.message = message;
      const toastEl = document.getElementById('successToast');
      if (toastEl) {
        const bsToast = new window.bootstrap.Toast(toastEl, {
          delay: duration
        });
        bsToast.show();
      }
    }
  }
}
</script>

<style scoped>
.toast-container {
  z-index: 1100;
}
</style>
