<template>
  <div class="home-page">
    <NavBar />

    <!-- 根据登录状态显示不同内容 -->
    <template v-if="isLoggedIn">
      <!-- 已登录用户显示用户中心 -->
      <main class="dashboard-content">
        <div class="container py-4">
          <div class="content-wrapper">
            <!-- 欢迎信息 -->
            <div class="welcome-section mb-5">
              <h2>欢迎回来, {{ username }}</h2>
              <p class="text-muted">今天想要做些什么？</p>
            </div>

            <!-- 快捷操作卡片 -->
            <div class="row g-4 mb-5">
              <div class="col-md-4">
                <div class="action-card" @click="$router.push('/profile')">
                  <div class="card-icon">
                    <i class="bi bi-person"></i>
                  </div>
                  <h3>个人中心</h3>
                  <p>管理您的个人信息和API密钥</p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="action-card" @click="$router.push('/public')">
                  <div class="card-icon">
                    <i class="bi bi-search"></i>
                  </div>
                  <h3>浏览提示词</h3>
                  <p>发现其他用户分享的优质提示词</p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="action-card" @click="$router.push('/download')">
                  <div class="card-icon">
                    <i class="bi bi-download"></i>
                  </div>
                  <h3>下载工具</h3>
                  <p>获取 Cursor 提示词记录工具</p>
                </div>
              </div>
            </div>

            <!-- 添加使用指南部分 -->
            <div class="guide-section">
              <h3 class="section-title mb-4">快速使用指南</h3>
              <div class="row g-4">
                <div class="col-md-6">
                  <div class="guide-card">
                    <div class="guide-icon">
                      <i class="bi bi-1-circle"></i>
                    </div>
                    <div class="guide-content">
                      <h4>下载并安装工具</h4>
                      <p>从下载页面获取最新版本的 Cursor 提示词记录工具，按照指引完成安装</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="guide-card">
                    <div class="guide-icon">
                      <i class="bi bi-2-circle"></i>
                    </div>
                    <div class="guide-content">
                      <h4>配置 API 密钥</h4>
                      <p>在个人中心获取 API 密钥，填入工具的配置界面中</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="guide-card">
                    <div class="guide-icon">
                      <i class="bi bi-3-circle"></i>
                    </div>
                    <div class="guide-content">
                      <h4>开始使用</h4>
                      <p>工具会自动记录您在 Cursor 中使用的提示词，无需手动操作</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="guide-card">
                    <div class="guide-icon">
                      <i class="bi bi-4-circle"></i>
                    </div>
                    <div class="guide-content">
                      <h4>管理提示词</h4>
                      <p>在网页端查看、编辑和分享您的提示词，打造个人知识库</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </template>
    
    <template v-else>
      <!-- 未登录用户显示欢迎页面 -->
      <main>
        <!-- 原有的 Hero 部分 -->
        <section class="hero">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <h1 class="hero-title mb-4">
                  智能 <span class="gradient-text">记录</span> 和<br>
                  <span class="gradient-text">管理</span> Cursor提示词
                </h1>
                <p class="hero-subtitle mb-5">
                  自动记录您与 Cursor 的每一次互动，<br>
                  让提示词管理变得简单高效。
                </p>
                <div class="d-flex gap-3">
                  <router-link 
                    to="/download" 
                    class="btn btn-primary btn-lg"
                  >
                    <i class="bi bi-download me-2"></i>
                    下载工具
                  </router-link>
                  <router-link 
                    to="/public"
                    class="btn btn-outline-light btn-lg"
                  >
                    浏览提示词
                  </router-link>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="demo-container">
                  <h3 class="text-center mb-4">立即开始使用</h3>
                  <div class="signup-form">
                    <div class="mb-3">
                      <input 
                        type="email" 
                        class="form-control form-control-lg"
                        v-model="email"
                        placeholder="输入您的邮箱..."
                      >
                    </div>
                    <button 
                      v-if="!isLoggedIn" 
                      class="btn btn-primary btn-lg w-100 mb-3"
                      @click="goToRegister"
                    >
                    注册 Cursor 提示词助手
                    </button>
                    <p class="text-center text-muted small">
                      注册即表示您同意我们的
                      <router-link to="/terms" class="text-light">服务条款</router-link>
                      和
                      <router-link to="/privacy" class="text-light">隐私政策</router-link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- 原有的特性介绍部分 -->
        <section class="features">
          <div class="container">
            <h2 class="text-center mb-5">为什么选择 Cursor 提示词助手</h2>
            <div class="row g-4">
              <div class="col-md-4">
                <div class="feature-card">
                  <div class="icon-container">
                    <i class="bi bi-collection"></i>
                  </div>
                  <h3>自动记录</h3>
                  <p>
                    实时捕捉与 Cursor 的每次交互，确保没有灵感被遗漏
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="feature-card">
                  <div class="icon-container">
                    <i class="bi bi-lightning"></i>
                  </div>
                  <h3>智能分类</h3>
                  <p>
                    轻松为提示词添加标签和分类，快速检索历史记录
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="feature-card">
                  <div class="icon-container">
                    <i class="bi bi-share"></i>
                  </div>
                  <h3>分享导出</h3>
                  <p>
                    支持多种格式导出，方便与团队成员共享提示词
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- 原有的探索部分 -->
        <section class="explore-section">
          <div class="container text-center">
            <h2 class="mb-4">探索更多可能</h2>
            <p class="mb-5">浏览其他用户分享的优质提示词，激发您的创作灵感</p>
            <router-link 
              to="/public" 
              class="btn btn-outline-light btn-lg"
            >
              探索提示词库
            </router-link>
          </div>
        </section>
      </main>
    </template>

    <!-- 保持原有的登录和注册对话框 -->
    <LoginDialog 
      :show="showLoginDialog" 
      @close="showLoginDialog = false"
      @login-success="handleLoginSuccess"
    />
    <RegisterDialog 
      :show="showRegisterDialog" 
      @close="showRegisterDialog = false"
      @register-success="handleRegisterSuccess"
    />
  </div>
</template>

<script>
import NavBar from './NavBar.vue'

export default {
  name: 'HomePage',
  components: {
    NavBar
  },
  data() {
    return {
      isLoggedIn: false,
      username: '',
      email: '',
      showLoginDialog: false,
      showRegisterDialog: false
    }
  },
  created() {
    // 检查登录状态
    const token = localStorage.getItem('token')
    const username = localStorage.getItem('username')
    if (token && username) {
      this.isLoggedIn = true
      this.username = username
    }
  },
  methods: {
    handleLogout() {
      this.isLoggedIn = false
      this.username = ''
      localStorage.removeItem('token')
      localStorage.removeItem('username')
    },
    goToRegister() {
      if (this.email) {
        this.$router.push({
          path: '/register',
          query: { email: this.email }
        })
      } else {
        this.$router.push('/register')
      }
    }
  }
}
</script>

<style scoped>
.home-page {
  min-height: 100vh;
  background: var(--bg-primary);
  color: var(--text-primary);
  padding-top: 60px;
}

.navbar {
  background: var(--navbar-bg);
}

.nav-link {
  color: var(--text-secondary);
  transition: color 0.3s;
}

.nav-link:hover {
  color: var(--text-primary);
}

.hero {
  padding: 4rem 0;
  position: relative;
  overflow: hidden;
}

.hero-title {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.gradient-text {
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-subtitle {
  font-size: 1.25rem;
  color: var(--text-secondary);
  line-height: 1.6;
}

.demo-container {
  background: var(--card-bg);
  border-radius: 1rem;
  padding: 2.5rem;
  backdrop-filter: blur(10px);
  border: 1px solid var(--card-border);
}

.signup-form input {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  padding: 0.8rem 1rem;
}

.signup-form input::placeholder {
  color: var(--text-secondary);
}

.signup-form input:focus {
  background: var(--card-bg);
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.2rem rgba(124, 58, 237, 0.25);
  color: var(--text-primary);
}

.signup-form .btn-primary {
  padding: 0.8rem;
  font-weight: 500;
}

.signup-form a {
  text-decoration: underline;
  color: var(--text-secondary) !important;
}

.signup-form a:hover {
  color: var(--secondary-color) !important;
}

.features {
  padding: 6rem 0;
  background: var(--bg-secondary);
}

.feature-card {
  background: var(--card-bg);
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s;
  border: 1px solid var(--card-border);
}

.feature-card:hover {
  transform: translateY(-5px);
}

.icon-container {
  width: 64px;
  height: 64px;
  margin: 0 auto 1.5rem;
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: var(--text-primary);
}

.btn-primary {
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  border: none;
}

.btn-outline-light {
  border-color: var(--border-color);
  color: var(--text-primary);
}

.btn-outline-light:hover {
  background: var(--card-bg);
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.explore-section {
  padding: 6rem 0;
  background: var(--bg-secondary);
  border-top: 1px solid var(--border-color);
}

.explore-section h2 {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--text-primary);
}

.explore-section p {
  color: var(--text-secondary);
  font-size: 1.2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.explore-section .btn-outline-light {
  padding: 1rem 2.5rem;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }
  
  .demo-container {
    margin-top: 3rem;
  }
}

/* 添加新的仪表盘样式 */
.dashboard-content {
  padding-top: 60px;
}

.welcome-section h2 {
  color: var(--text-primary);
  font-weight: 600;
}

.action-card {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.action-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.card-icon {
  width: 64px;
  height: 64px;
  margin: 0 auto 1.5rem;
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: white;
}

.action-card h3 {
  color: var(--text-primary);
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.action-card p {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin: 0;
}

.project-card {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 0.5rem;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.project-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.project-card h4 {
  color: var(--text-primary);
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.project-meta {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--border-color);
}

/* 添加新的样式 */
.guide-section, .resources-section {
  background: var(--card-bg);
  border-radius: 1rem;
  padding: 2rem;
  border: 1px solid var(--card-border);
}

.section-title {
  color: var(--text-primary);
  font-size: 1.5rem;
  font-weight: 600;
}

.guide-card {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.5rem;
  background: var(--bg-secondary);
  border-radius: 0.5rem;
  border: 1px solid var(--card-border);
}

.guide-icon {
  font-size: 1.5rem;
  color: var(--primary-color);
}

.guide-content h4 {
  color: var(--text-primary);
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.guide-content p {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin: 0;
}

.resource-card {
  text-align: center;
  padding: 2rem;
  background: var(--bg-secondary);
  border-radius: 0.5rem;
  border: 1px solid var(--card-border);
}

.resource-card i {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.resource-card h4 {
  color: var(--text-primary);
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.resource-card p {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

/* 调整原有样式的间距 */
.welcome-section {
  margin-bottom: 3rem;
}

.action-card {
  height: 100%;
}

/* 添加移动端适配 */
@media (max-width: 576px) {
  .home-page {
    padding-top: 60px;
  }

  .hero {
    padding: 2rem 0;
    text-align: center;
  }

  .hero .row {
    flex-direction: column;
  }

  .hero-title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .hero-subtitle {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    br {
      display: none;
    }
  }

  .hero .d-flex {
    flex-direction: column;
    width: 100%;
  }

  .hero .btn {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .demo-container {
    margin-top: 2rem;
    padding: 1.5rem;
  }

  .signup-form {
    padding: 0;
  }

  .signup-form input {
    padding: 0.75rem;
  }

  .signup-form .btn {
    padding: 0.75rem;
  }

  .features {
    padding: 3rem 0;
  }

  .feature-card {
    padding: 1.5rem;
    margin-bottom: 1rem;
  }

  .icon-container {
    width: 48px;
    height: 48px;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  .explore-section {
    padding: 3rem 0;
  }

  .explore-section h2 {
    font-size: 1.75rem;
  }

  .explore-section p {
    font-size: 1rem;
    padding: 0 1rem;
  }

  .dashboard-content {
    padding-top: 1rem;
  }

  .welcome-section {
    text-align: center;
    margin-bottom: 2rem;
  }

  .action-card {
    margin-bottom: 1rem;
  }

  .guide-section {
    padding: 1.5rem;
  }

  .guide-card {
    padding: 1rem;
    flex-direction: column;
    text-align: center;
  }

  .guide-icon {
    margin: 0 auto 1rem;
  }
}

/* 添加平板适配 */
@media (min-width: 577px) and (max-width: 992px) {
  .home-page {
    padding-top: 60px;
  }

  .hero {
    padding: 3rem 0;
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .demo-container {
    margin-top: 2rem;
  }

  .features .row {
    margin: 0 -0.5rem;
  }

  .features .col-md-4 {
    padding: 0 0.5rem;
  }

  .guide-section .row {
    margin: 0 -0.5rem;
  }

  .guide-section .col-md-6 {
    padding: 0 0.5rem;
  }
}

/* 添加横屏手机适配 */
@media (max-height: 600px) and (orientation: landscape) {
  .hero-section {
    min-height: auto;
    padding: 2rem 0;
  }

  .hero-content {
    max-width: 600px;
  }

  .features-section,
  .explore-section {
    padding: 2rem 0;
  }
}

/* 优化交互体验 */
@media (hover: none) {
  .feature-card:hover,
  .action-card:hover {
    transform: none;
  }

  .btn:hover {
    transform: none;
  }
}

/* 优化加载性能 */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* 优化触摸目标 */
@media (pointer: coarse) {
  .btn,
  .nav-link,
  .feature-card,
  .action-card {
    min-height: 44px;
    padding: 12px;
  }

  .btn-icon {
    min-width: 44px;
    min-height: 44px;
  }
}

/* 修改现有样式 */
.home-page {
  min-height: 100vh;
  background: var(--bg-primary);
  color: var(--text-primary);
  padding-top: 60px;
}

/* 修改 hero 区域样式 */
.hero {
  padding: 4rem 0;
  position: relative;
  overflow: hidden;
}

/* 优化容器宽度 */
.container {
  max-width: 1140px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
}

/* 优化按钮触摸区域 */
.btn {
  min-height: 44px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* 优化链接可点击区域 */
.nav-link {
  padding: 0.5rem 1rem;
  min-height: 44px;
  display: inline-flex;
  align-items: center;
}

/* 添加过渡动画 */
.feature-card,
.action-card,
.guide-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* 优化加载性能 */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
</style>

