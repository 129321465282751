<template>
  <div class="terms-page">
    <NavBar />
    <div class="container mt-5 pt-4">
      <div class="content-wrapper">
        <h1>服务条款</h1>
        
        <div class="terms-content">
          <section class="mb-4">
            <h2>1. 服务说明</h2>
            <p class="section-subtitle">最后更新时间：2024年10月29日</p>
            <p>Cursor 提示词记录助手（以下简称"本服务"）是一个帮助用户记录和管理与 Cursor AI 交互过程中生成的提示词的在线服务。本服务由武汉帛曲科技有限公司（以下简称"服务提供方"）提供技术支持和运营维护。</p>
            <div class="important-note">
              <p><strong>重要提示：</strong>使用本服务即表示您同意本条款的全部内容。如果您不同意本条款的任何部分，请勿使用本服务。</p>
            </div>
          </section>

          <section class="mb-5">
            <h2 class="mb-4">2. 用户注册与账号安全</h2>
            <p>2.1 用户需要注册账号才能使用本服务的完整功能。注册时需要提供真实、准确的个人信息。</p>
            <p>2.2 用户应妥善保管账号和密码，因账号密码保管不当造成的损失由用户自行承担。</p>
            <p>2.3 如发现账号被盗用，请立即联系我们。</p>
          </section>

          <section class="mb-5">
            <h2 class="mb-4">3. 服务使用规则</h2>
            <p>3.1 用户应遵守中华人民共和国相关法律法规。</p>
            <p>3.2 用户不得利用本服务从事违法违规活动。</p>
            <p>3.3 用户应尊重知识产权，不得侵犯他人权益。</p>
          </section>

          <section class="mb-5">
            <h2 class="mb-4">4. 数据存储与安全</h2>
            <p>4.1 我们会采取合理措施保护用户数据安全。</p>
            <p>4.2 用户理解并同意，任何数据传输都存在风险。</p>
            <p>4.3 建议用户定期备份重要数据。</p>
          </section>

          <section class="mb-5">
            <h2 class="mb-4">5. 服务变更与终止</h2>
            <p>5.1 我们保留随时修改或终止服务的权利。</p>
            <p>5.2 服务变更会提前通知用户。</p>
            <p>5.3 用户可以随时终止使用本服务。</p>
          </section>

          <section class="mb-5">
            <h2 class="mb-4">6. 免责声明</h2>
            <p>6.1 本服务不对因不可抗力导致的服务中断负责。</p>
            <p>6.2 用户对使用本服务的结果自行承担风险。</p>
          </section>

          <section class="mb-5">
            <h2 class="mb-4">7. 其他条款</h2>
            <p>7.1 本条款的最终解释权归服务提供方所有。</p>
            <p>7.2 如有争议，双方应友好协商解决。</p>
          </section>

          <section class="contact-info">
            <h2>联系我们</h2>
            <p>如果您对本服务条款有任何疑问或建议，请通过以下方式联系我们：</p>
            <ul>
              <li>客服邮箱：<a href="mailto:support@cursorai.online">support@cursorai.online</a></li>
              <li>公司地址：武汉市东湖新技术开发区光谷大道</li>
              <li>工作时间：周一至周五 9:00-18:00（节假日除外）</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from './NavBar.vue'

export default {
  components: {
    NavBar
  },
  name: 'TermsPage'
}
</script>

<style scoped>
.terms-page {
  min-height: 100vh;
  background: var(--bg-primary);
  color: var(--text-primary);
  padding-top: 60px;
}

.container {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.content-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1rem;
}

h1 {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: left;
}

h2 {
  color: var(--heading-color);
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

p {
  color: var(--text-secondary);
  line-height: 1.5;
  margin-bottom: 0.75rem;
  font-size: 0.95rem;
}

section {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 1rem;
  text-align: left;
}

.section-subtitle {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.important-note {
  background: var(--accent-bg);
  border-left: 3px solid var(--primary-color);
  padding: 1rem;
  margin: 1rem 0;
}

.contact-info {
  background: var(--secondary-bg);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;
}

.contact-info a {
  color: var(--link-color);
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}
</style> 