<template>
  <div class="project-detail-page">
    <NavBar 
      :is-logged-in="isLoggedIn"
      :username="username"
      @logout="handleLogout"
    />
    
    <main class="container py-4">
      <div class="content-wrapper">
        <!-- 返回按钮和标题 -->
        <div class="d-flex justify-content-between align-items-center mb-4">
          <div class="d-flex align-items-center">
            <button class="btn btn-sm btn-outline-primary me-2" @click="goBack">
              <i class="bi bi-arrow-left me-1"></i>返回
            </button>
            <h4 class="mb-0 fw-bold">{{ project.name }}</h4>
          </div>
          <div class="btn-group btn-group-sm">
            <button 
              class="btn btn-outline-primary"
              @click="editProject"
            >
              <i class="bi bi-pencil"></i>
            </button>
            <button 
              class="btn btn-outline-danger"
              @click="deleteProject"
            >
              <i class="bi bi-trash"></i>
            </button>
          </div>
        </div>

        <!-- Tab 导航 -->
        <ul class="nav nav-tabs mb-4">
          <li class="nav-item">
            <a 
              class="nav-link" 
              :class="{ active: currentTab === 'info' }"
              @click.prevent="currentTab = 'info'"
              href="#"
            >
              项目信息
            </a>
          </li>
          <li class="nav-item">
            <a 
              class="nav-link" 
              :class="{ active: currentTab === 'prompts' }"
              @click.prevent="currentTab = 'prompts'"
              href="#"
            >
              项目提示词
            </a>
          </li>
        </ul>

        <!-- 项目信息 Tab -->
        <div v-if="currentTab === 'info'">
          <!-- 项目信息卡片 -->
          <div class="card mb-4">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <h5 class="card-title">项目描述</h5>
                  <p class="mb-3 text-secondary">{{ project.desc || '暂无描述' }}</p>
                  <div class="meta-item mt-3">
                    <label>创建时间：</label>
                    <span>{{ formatDateTime(project.create_time) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 关联的工作空间 -->
          <div class="card mb-4">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h5 class="mb-0">关联的工作空间</h5>
              <button 
                class="btn btn-primary btn-sm"
                @click="openWorkspaceDialog"
              >
                <i class="bi bi-plus-lg me-1"></i>关联工作空间
              </button>
            </div>
            <div class="card-body">
              <div v-if="workspaces.length === 0" class="text-center py-3 text-muted">
                暂无关联的工作空间
              </div>
              <div v-else class="table-responsive">
                <table class="table table-hover align-middle">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">工作空间</th>
                      <th scope="col" class="text-end">操作</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="workspace in workspaces" :key="workspace.id">
                      <td>{{ workspace.id }}</td>
                      <td>{{ workspace.workspace }}</td>
                      <td class="text-end">
                        <button 
                          class="btn btn-outline-danger btn-sm"
                          @click="removeWorkspace(workspace.id)"
                          title="移除关联"
                        >
                          <i class="bi bi-x-lg"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- 项目提示词 Tab -->
        <div v-else-if="currentTab === 'prompts'">
          <div v-if="isLoading && !projectPrompts.length" class="text-center py-5">
            <LoadingSpinner />
          </div>
          <div v-else>
            <FilterSort 
              v-model:searchQuery="searchQuery"
              :show-workspace-filter="false"
              :loading="isLoading"
              @search="handleSearch"
            />
            
            <div v-if="projectPrompts.length === 0" class="text-center py-3 text-muted">
              暂无提示词
            </div>
            <div v-else>
              <div class="mb-3 text-secondary">
                共找到 {{ total }} 条提示词
              </div>
              
              <ProjectPrompts 
                :prompts="projectPrompts"
                @refresh-prompts="fetchProjectPrompts"
                @show-toast="showToast"
              />

              <!-- 替换分页为加载更多按钮 -->
              <div v-if="hasMore" class="text-center mt-4">
                <button 
                  class="btn btn-outline-primary"
                  @click="loadMore"
                  :disabled="isLoading"
                >
                  <span v-if="isLoading">
                    <i class="bi bi-arrow-repeat spin me-2"></i>加载中...
                  </span>
                  <span v-else>
                    <i class="bi bi-plus-lg me-2"></i>加载更多
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- 关联工作空间对话框 -->
    <div class="modal" tabindex="-1" :class="{ 'd-block': showWorkspaceDialog }">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">关联工作空间</h5>
            <button 
              type="button" 
              class="btn-close" 
              @click="showWorkspaceDialog = false"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="workspaceSelect" class="form-label modal-label">选择工作空间</label>
              <select 
                class="form-select" 
                id="workspaceSelect"
                v-model="selectedWorkspaceId"
              >
                <option value="">请选择工作空间</option>
                <option 
                  v-for="ws in availableWorkspaces" 
                  :key="ws.id"
                  :value="ws.id"
                  :disabled="workspaces.some(w => w.id === ws.id)"
                >
                  {{ ws.workspace }} ({{ ws.label || '无标签' }})
                </option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button 
              type="button" 
              class="btn btn-secondary" 
              @click="showWorkspaceDialog = false"
            >
              取消
            </button>
            <button 
              type="button" 
              class="btn btn-primary"
              @click="addWorkspace"
              :disabled="!selectedWorkspaceId"
            >
              确定
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 编辑项目对话框 -->
    <CreateProjectDialog 
      ref="createProjectDialog"
      :is-edit="true"
      :edit-project="project"
      @project-updated="fetchProjectDetails"
    />
  </div>
</template>

<script>
import NavBar from './NavBar.vue'
import CreateProjectDialog from './CreateProjectDialog.vue'
import { formatDateTime } from '@/utils/helpers'
import { getProjectStatus, getStatusClass } from '@/utils/projectUtils'
import FilterSort from './FilterSort.vue'  // 添加导入
import ProjectPrompts from './ProjectPrompts.vue'  // 导入新组件
import apiService, { API_ENDPOINTS } from '@/services/api'

export default {
  name: 'ProjectDetailPage',
  components: {
    NavBar,
    CreateProjectDialog,
    FilterSort,
    ProjectPrompts  // 替换 MyPrompts
  },
  data() {
    return {
      isLoggedIn: !!localStorage.getItem('token'),
      username: localStorage.getItem('username'),
      project: {
        id: null,
        name: '',
        desc: '',
        create_time: '',
        update_time: ''
      },
      workspaces: [],
      showWorkspaceDialog: false,
      selectedWorkspaceId: '',
      availableWorkspacesList: [], // 添加这个来存储从 API 获取的工作空间列表
      currentTab: 'info',
      projectPrompts: [],
      isLoading: false,
      searchQuery: '',
      workspaceFilter: '',
      currentSort: 'latest',
      currentPage: 1,
      pageSize: 10,
      total: 0,
      hasMore: true, // 添加 hasMore 属性
      offset: 0, // 添加 offset 属性
      limit: 20, // 修改为 limit
    }
  },
  computed: {
    // 合并两个 availableWorkspaces 计算属性
    availableWorkspaces() {
      // 如果是工作空间选择器，返回所有可用的工作空间
      if (this.showWorkspaceDialog) {
        return this.availableWorkspacesList;
      }
      
      // 如果是提示词过滤，返回提示词中的工作空间
      const workspaces = new Set();
      this.projectPrompts.forEach(prompt => {
        if (prompt.workspace) {
          workspaces.add(prompt.workspace);
        }
      });
      return Array.from(workspaces).sort();
    },
    filteredAndSortedPrompts() {
      let result = [...this.projectPrompts];

      // 搜索过滤
      if (this.searchQuery.trim()) {
        const query = this.searchQuery.toLowerCase();
        result = result.filter(prompt => 
          prompt.value.toLowerCase().includes(query)
        );
      }

      // 工作空间过滤
      if (this.workspaceFilter) {
        result = result.filter(prompt => 
          prompt.workspace === this.workspaceFilter
        );
      }

      // 排序
      switch (this.currentSort) {
        case 'latest':
          return result.sort((a, b) => {
            const dateA = a.upload_time ? new Date(a.upload_time) : new Date(0);
            const dateB = b.upload_time ? new Date(b.upload_time) : new Date(0);
            return dateB - dateA;
          });
        case 'az':
          return result.sort((a, b) => {
            const valueA = a.value || '';
            const valueB = b.value || '';
            return valueA.localeCompare(valueB);
          });
        default:
          return result;
      }
    },
    totalPages() {
      return Math.ceil(this.total / this.pageSize)
    }
  },
  methods: {
    formatDateTime,
    getProjectStatus,
    getStatusClass,
    goBack() {
      this.$router.back()
    },
    async fetchProjectDetails() {
      try {
        const [projectData, workspacesData] = await Promise.all([
          apiService.project.getDetail(this.$route.params.id),
          apiService.request(API_ENDPOINTS.PROJECT.PROJECT_WORKSPACES(this.$route.params.id)) // 更新这里
        ]);

        this.project = projectData.data;
        this.workspaces = workspacesData.data || [];
      } catch (error) {
        console.error('获取项目详情失败:', error);
        alert('获取项目详情失败');
      }
    },
    editProject() {
      this.$refs.createProjectDialog.showDialog()
    },
    async deleteProject() {
      if (!confirm('确定要删除这个项目吗？此操作不可恢复。')) return
      
      try {
        await apiService.project.delete(this.project.id);
        this.$router.push('/profile');
      } catch (error) {
        console.error('删除项目失败:', error);
        alert('删除失败，请稍后重试');
      }
    },
    async fetchWorkspaces() {
      try {
        const data = await apiService.request(API_ENDPOINTS.PROJECT.WORKSPACES);
        this.availableWorkspacesList = data.data || [];
      } catch (error) {
        console.error('获取工作空间列表失败:', error);
        alert('获取工作空间列表失败');
      }
    },
    async addWorkspace() {
      if (!this.selectedWorkspaceId) return;
      
      try {
        await apiService.request(API_ENDPOINTS.PROJECT.ADD_WORKSPACE(this.project.id, this.selectedWorkspaceId), {
          method: 'POST',
          body: JSON.stringify({
            projectID: this.project.id,
            workspaceID: this.selectedWorkspaceId
          })
        });

        this.showWorkspaceDialog = false;
        this.selectedWorkspaceId = '';
        await this.fetchProjectDetails();
      } catch (error) {
        console.error('添加工作空间失败:', error);
        alert('添加失败，请稍后重试');
      }
    },
    async removeWorkspace(workspaceId) {
      if (!confirm('确定要移除这个工作空间吗？')) return
      
      console.log('开始移除工作空间:', {
        projectId: this.project.id,
        workspaceId: workspaceId
      });
      
      try {
        console.log('准备发送移除请求:', {
          endpoint: API_ENDPOINTS.PROJECT.REMOVE_WORKSPACE(this.project.id, workspaceId),
          requestBody: {
            projectID: this.project.id,
            workspaceID: workspaceId
          }
        });

        await apiService.request(API_ENDPOINTS.PROJECT.REMOVE_WORKSPACE(this.project.id, workspaceId), {
          method: 'DELETE',
          body: JSON.stringify({
            projectID: this.project.id,
            workspaceID: workspaceId
          })
        });

        console.log('移除工作空间成功');
        await this.fetchProjectDetails();
      } catch (error) {
        console.error('移除工作空间失败:', {
          error,
          errorMessage: error.message,
          stack: error.stack,
          requestInfo: {
            endpoint: API_ENDPOINTS.PROJECT.REMOVE_WORKSPACE,
            projectId: this.project.id,
            workspaceId: workspaceId
          }
        });
        alert('移除失败，请稍后重试');
      }
    },
    async openWorkspaceDialog() {
      this.selectedWorkspaceId = '';
      await this.fetchWorkspaces(); // 获取可用的工作空间列表
      this.showWorkspaceDialog = true;
    },
    handleLogout() {
      localStorage.removeItem('token')
      localStorage.removeItem('username')
      this.$router.push('/')
    },
    async fetchProjectPrompts(isLoadMore = false) {
      if (this.isLoading) return;
      this.isLoading = true;
      
      try {
        // 如果不是加载更多,重置 offset
        if (!isLoadMore) {
          this.offset = 0;
          this.projectPrompts = [];
        }

        // 构建请求参数
        const params = new URLSearchParams({
          offset: this.offset,
          limit: this.limit
        });

        // 添加搜索参数
        if (this.searchQuery) {
          params.append('search', this.searchQuery);
        }

        const response = await apiService.request(`${API_ENDPOINTS.PROJECT.PROMPTS(this.project.id)}&${params}`);

        if (response.error_code === 0) {
          const newPrompts = response.data.prompts.map(prompt => ({
            id: prompt.id,
            value: prompt.value,
            command_type: prompt.command_type,
            workspace: prompt.workspace,
            created_at: prompt.created_at,
            upload_time: prompt.upload_time || prompt.timestamp,
            is_public: prompt.is_public,
            user_id: prompt.user_id,
            md5: prompt.md5
          }));

          // 如果是加载更多,追加数据
          if (isLoadMore) {
            this.projectPrompts = [...this.projectPrompts, ...newPrompts];
          } else {
            this.projectPrompts = newPrompts;
          }
          
          this.total = response.data.total;
          this.hasMore = response.data.has_more || false;
          this.offset += newPrompts.length;
        }
      } catch (error) {
        console.error('[ProjectDetailPage] 获取项目提示词失败:', error);
        this.showToast('获取项目提示词失败');
      } finally {
        this.isLoading = false;
      }
    },

    // 添加加载更多方法
    async loadMore() {
      if (this.isLoading || !this.hasMore) return;
      await this.fetchProjectPrompts(true);
    },

    // 修改搜索处理方法,与 PublicPage 保持一致
    async handleSearch(query) {
      console.log('[ProjectDetailPage] 处理搜索:', query);
      this.searchQuery = query;
      this.offset = 0; // 重置 offset
      this.projectPrompts = []; // 清空现有数据
      await this.fetchProjectPrompts(false); // 重新获取数据
    },

    showToast(message) {
      // 如果使用了 Toast 组，实现显示消息的方法
      alert(message); // 临时使用 alert
    },
    resetWorkspaceFilter() {
      this.workspaceFilter = '';
    }
  },
  watch: {
    currentTab(newTab) {
      if (newTab === 'prompts') {
        this.fetchProjectPrompts();
      }
    }
  },
  created() {
    if (!this.isLoggedIn) {
      this.$router.push('/')
      return
    }
    this.fetchProjectDetails()
  }
}
</script>

<style scoped>
.project-detail-page {
  min-height: 100vh;
  background: var(--bg-primary);
  padding-top: 60px;
}

.content-wrapper {
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
}

.project-header {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.project-title {
  color: var(--text-primary);
  font-size: 1.75rem;
  font-weight: 600;
  margin: 0;
}

.project-desc {
  color: var(--text-secondary);
  font-size: 1rem;
  margin-bottom: 1rem;
}

.project-meta {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.meta-item {
  display: inline-flex;
  align-items: center;
}

.meta-divider {
  margin: 0 1rem;
  color: var(--border-color);
}

.section-header h2 {
  color: var(--text-primary);
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

.workspace-card {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

.workspace-card:hover {
  border-color: var(--primary-color);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.workspace-name {
  color: var(--text-primary);
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
}

.workspace-path {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin: 0.5rem 0 0;
}

.empty-state {
  background: var(--card-bg);
  border: 1px dashed var(--border-color);
  border-radius: 0.5rem;
  padding: 2rem;
  text-align: center;
  color: var(--text-secondary);
}

/* 按钮样式 */
.btn-primary {
  background: var(--primary-color) !important;
  border-color: var(--primary-color);
  color: white !important;
}

.btn-primary:hover {
  opacity: 0.9;
}

.btn-outline-primary {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  background: transparent !important;
}

.btn-outline-primary:hover {
  background: var(--primary-color) !important;
  color: white !important;
}

.btn-outline-danger {
  color: var(--error-color) !important;
  border-color: var(--error-color);
  background: transparent !important;
}

.btn-outline-danger:hover {
  background: var(--error-color) !important;
  color: white !important;
}

/* 表样式 */
.table-responsive {
  margin: 0 -1rem;
  padding: 0 1rem;
}

.table {
  margin-bottom: 0;
  color: var(--text-primary);
}

.table th {
  font-weight: 500;
  color: var(--text-secondary);
  border-bottom-color: var(--border-color);
  white-space: nowrap;
}

.table td {
  vertical-align: middle;
  border-bottom-color: var(--border-color);
}

.table-hover tbody tr:hover {
  background-color: var(--bg-secondary);
}

/* 修改按钮组样式 */
.btn-group-sm .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.btn-group-sm .btn i {
  font-size: 1rem;
}

/* 标题样式调整 */
h4.fw-bold {
  font-size: 1.25rem;
  margin-right: 1rem;
}

@media (max-width: 576px) {
  .btn-group-sm .btn {
    padding: 0.25rem 0.4rem;
  }
  
  h4.fw-bold {
    font-size: 1.1rem;
  }
}

/* 移动端适配 */
@media (max-width: 576px) {
  /* 调整表格布局 */
  .table {
    display: block;
    width: 100%;
  }

  .table thead {
    display: none; /* 隐藏表头 */
  }

  .table tbody {
    display: block;
    width: 100%;
  }

  .table tr {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 0;
    border-bottom: 1px solid var(--border-color);
  }

  .table td {
    border: none;
    padding: 0.25rem 0;
  }

  /* 调整删除按钮 */
  .table td:last-child {
    padding-left: 0.5rem;
  }

  .btn-outline-danger {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    white-space: nowrap;
  }

  /* 调整工作空间信息显示 */
  .table td:first-child {
    width: auto;
    font-weight: 500;
    margin-right: 0.5rem;
  }

  .table td:nth-child(2) {
    flex: 1;
    word-break: break-all;
  }

  /* 卡片内边距调整 */
  .card-body {
    padding: 1rem;
  }

  /* 按钮组样式调整 */
  .btn-group {
    display: flex;
    gap: 0.5rem;
  }

  .btn-group .btn {
    flex: 1;
  }
}

/* 平板适配 */
@media (min-width: 577px) and (max-width: 992px) {
  .table td {
    padding: 0.75rem;
  }

  .btn-outline-danger {
    padding: 0.375rem 0.75rem;
  }
}

/* 添加加载动画样式 */
.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.project-detail {
  min-height: 100vh;
  background: var(--bg-primary);
}

.card {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
}

.card-title {
  color: var(--text-primary);
}

.card-text {
  color: var(--text-secondary);
}

h4.fw-bold {
  color: var(--text-primary);
}

/* Tab 样式适配 */
.nav-tabs {
  border-bottom-color: var(--border-color);
}

.nav-tabs .nav-link {
  color: var(--text-secondary);
  border: none;
  border-bottom: 2px solid transparent;
}

.nav-tabs .nav-link:hover {
  color: var(--text-primary);
  border-color: transparent;
}

.nav-tabs .nav-link.active {
  color: var(--primary-color);
  background: transparent;
  border-bottom: 2px solid var(--primary-color);
}

/* 工作空间列表适配 */
.table {
  color: var(--text-primary);
}

.table td {
  border-color: var(--border-color);
}

.table td:first-child {
  color: var(--text-primary);
}

.table td:nth-child(2) {
  color: var(--text-secondary);
}

/* 工作空间表格深色模式完整适配 */
.table {
  color: var(--text-primary);
  background: var(--card-bg);
}

.table tr {
  border-color: var(--border-color);
}

.table td {
  background: var(--card-bg);
  border-color: var(--border-color);
}

.table tbody tr:hover td {
  background: var(--bg-secondary);
}

.table td:first-child {
  color: var(--text-primary);
  font-weight: 500;
}

.table td:nth-child(2) {
  color: var(--text-secondary);
}

/* 工作空间表格标题行深色模式适配 */
.table thead th {
  background: var(--bg-secondary);
  color: var(--text-primary);
  border-bottom: 2px solid var(--border-color);
  font-weight: 600;
}

.table thead tr {
  border-color: var(--border-color);
}

/* 确保标题行的边框颜色正确 */
.table > :not(:first-child) {
  border-top: 2px solid var(--border-color);
}

/* 项目标题深色模式适配 */
.project-title {
  color: var(--text-primary);
}

/* 次要文本深色模式适配 */
.text-secondary {
  color: var(--text-secondary) !important;
}

/* 图标颜色适配深色模式 */
.bi {
  color: var(--text-secondary);
}

.btn:hover .bi {
  color: var(--text-primary);
}

/* 特别处理删除图标 */
.bi-trash {
  color: var(--text-secondary);
}

.btn-outline-danger:hover .bi-trash {
  color: #fff; /* 删除按钮悬停时图标显示白色 */
}
</style>

