<template>
  <div>
    <div class="row mb-4">
      <div class="col">
        <div class="input-group">
          <input 
            type="text" 
            class="form-control search-input" 
            v-model="localSearchQuery"
            placeholder="搜索提示词..."
            @keyup.enter="handleSearch"
          >
          <button 
            class="btn btn-primary search-btn" 
            type="button"
            @click="handleSearch"
            :disabled="loading"
          >
            <i :class="['bi', loading ? 'bi-arrow-repeat spin' : 'bi-search']"></i>
          </button>
        </div>
      </div>
    </div>

    <div v-if="showWorkspaceFilter" class="row mb-4">
      <div class="col-auto">
        <div class="dropdown">
          <button 
            class="btn btn-outline-light dropdown-toggle workspace-btn" 
            type="button" 
            data-bs-toggle="dropdown"
          >
            工作空间: {{ workspaceFilter || '全部' }}
          </button>
          <ul class="dropdown-menu dark-dropdown">
            <li>
              <a class="dropdown-item" href="#" @click.prevent="$emit('reset-workspace')">全部</a>
            </li>
            <li v-for="workspace in workspaces" :key="workspace">
              <a 
                class="dropdown-item" 
                href="#" 
                @click.prevent="$emit('update:workspaceFilter', workspace)"
              >
                {{ workspace }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterSort',
  props: {
    searchQuery: String,
    workspaceFilter: String,
    currentSort: String,
    workspaces: Array,
    showWorkspaceFilter: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localSearchQuery: this.searchQuery || ''
    }
  },
  methods: {
    handleSearch() {
      if (this.loading) return;
      // 触发搜索事件,让父组件处理搜索逻辑
      this.$emit('search', this.localSearchQuery);
    }
  },
  watch: {
    searchQuery(newVal) {
      this.localSearchQuery = newVal;
    }
  }
}
</script>

<style scoped>
.search-input {
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-right: none;
  color: var(--text-primary);
  padding: 0.8rem 1rem;
}

.search-input:focus {
  background: var(--bg-secondary);
  border-color: var(--primary-color);
  box-shadow: none;
  color: var(--text-primary);
}

.search-input::placeholder {
  color: var(--text-secondary);
}

.search-btn {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: white;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-btn:hover {
  opacity: 0.9;
}

.search-btn i {
  font-size: 1.1rem;
}

.input-group:focus-within .search-btn {
  border-color: var(--primary-color);
}

.workspace-btn {
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
}

.workspace-btn:hover {
  background: var(--bg-secondary);
  border-color: var(--primary-color);
  color: var(--text-primary);
}

.dark-dropdown {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
}

.dark-dropdown .dropdown-item {
  color: var(--text-primary);
}

.dark-dropdown .dropdown-item:hover {
  background: var(--bg-secondary);
  color: var(--primary-color);
}

/* 移动端适配 */
@media (max-width: 576px) {
  .search-input,
  .search-btn {
    padding: 0.6rem 1rem;
  }

  .search-btn {
    padding: 0 1rem;
  }
}
</style>
