<template>
  <div class="download-page">
    <NavBar />
    
    <div class="content-wrapper py-5">
      <h1>下载 Cursor 提示词助手</h1>
      
      <section class="download-section mb-4">
        <div class="download-card">
          <div class="icon-container mb-3">
            <i class="bi bi-windows"></i>
          </div>
          <h3>Windows 版本</h3>
          <p class="version-info">版本 {{ version }} | {{ currentDate }}</p>
          <a 
            :href="downloadUrl"
            class="btn btn-primary btn-lg"
            target="_blank"
          >
            <i class="bi bi-download me-2"></i>
            下载 Windows 版本
          </a>
        </div>
      </section>

      <section class="guide-section">
        <h2>使用指南</h2>
        
        <div class="guide-step">
          <h3>1. 安装说明</h3>
          <div class="windows-guide">
            <h4><i class="bi bi-windows me-2"></i>Windows</h4>
            <ol>
              <li>下载 .exe 安装文件</li>
              <li>双击运行安装程序</li>
              <li>根据安装向导完成安装</li>
            </ol>
          </div>
        </div>

        <div class="guide-step">
          <h3>2. 配置说明</h3>
          <ol>
            <li>
              <strong>登录账号</strong>
              <p>打开应用后，使用您的 cursorai.online 账号登录。如果没有账号，请先在网站注册。</p>
            </li>
            <li>
              <strong>设置 API 密钥</strong>
              <p>在设置页面填入您的 API 密钥。API 密钥可以在网站 [个人中心] - [设置] 中生成。</p>
            </li>
          </ol>
        </div>

        <div class="guide-step">
          <h3>3. 使用说明</h3>
          <ol>
            <li>
              <strong>自动记录</strong>H
              <p>工具会自动记录您在 Cursor 中使用的所有提示词，无需手动操作。</p>
            </li>
            <li>
              <strong>提示词管理</strong>
              <p>可以在网站个人中心为提示词添加标签、描述，方便后续查找和使用。</p>
            </li>
            <li>
              <strong>数据同步</strong>
              <p>提示词会自动同步到云端，可以在网站 <a href="https://cursorai.online" target="_blank">https://cursorai.online</a> 上查看和管。</p>
            </li>
            <li>
              <strong>导出分享</strong>
              <p>个人中心支持导出提示词到本地文件，方便分享和备份。</p>
            </li>
          </ol>
        </div>

        <div class="guide-step">
          <h3>4. 常见问题</h3>
          <div class="faq-list">
            <div class="faq-item">
              <h4>工具无法启动？</h4>
              <p>请检查是否以管理员权限运行，或是否被安全软件拦截。</p>
            </div>
            <div class="faq-item">
              <h4>数据同步失败？</h4>
              <p>检查网络连接和 API 密钥是否正确。如果问题持续，请联系客服。</p>
            </div>
          </div>
        </div>

        <div class="support-section">
          <h3>获取帮助</h3>
          <p>如果您在使用过程中遇到任何问题，可以通过以下方式获取帮助：</p>
          <ul>
            <li>发送邮件至：<a href="mailto:support@cursorai.online">support@cursorai.online</a></li>
            <li>加入用户交流群：QQ群 967941282</li>
          </ul>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import NavBar from './NavBar.vue'

export default {
  name: 'DownloadPage',
  components: {
    NavBar
  },
  data() {
    return {
      version: '1.0.12'
    }
  },
  computed: {
    currentDate() {
      return "2024-11-05"
    },
    downloadUrl() {
      return `https://v8cloud.oss-cn-hangzhou.aliyuncs.com/cursor-history/CursorHistory_Setup_${this.version}.exe`
    }
  }
}
</script>

<style scoped>
.download-page {
  min-height: 100vh;
  background: var(--bg-primary);
  color: var(--text-primary);
  padding-top: 60px;
}

.content-wrapper {
  max-width: 980px;
  margin: 0 auto;
  padding: 0 15px;
}

h1 {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 2rem;
}

h2 {
  color: var(--secondary-color);
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  text-align: left;
}

h3 {
  color: var(--text-primary);
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

h4 {
  color: var(--text-primary);
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.download-card {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}

.icon-container {
  font-size: 3rem;
  color: var(--primary-color);
}

.version-info {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.guide-section {
  margin-top: 3rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.guide-step {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: left;
}

ol, ul {
  color: var(--text-secondary);
  padding-left: 1.25rem;
  margin-bottom: 1rem;
}

li {
  margin-bottom: 0.5rem;
}

li strong {
  color: var(--text-primary);
  display: block;
  margin-bottom: 0.25rem;
}

p {
  color: var(--text-secondary);
  font-size: 0.95rem;
  line-height: 1.5;
  margin-bottom: 0.75rem;
}

.faq-item {
  margin-bottom: 1.5rem;
}

.support-section {
  background: var(--bg-secondary);
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-top: 2rem;
  text-align: left;
}

.support-section a {
  color: var(--primary-color);
  text-decoration: none;
}

.support-section a:hover {
  text-decoration: underline;
}

.btn-primary {
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  border: none;
  padding: 0.8rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .download-card {
    margin-bottom: 1rem;
  }
}
</style> 