export const PROJECT_STATUS = {
  IN_PROGRESS: 1,
  COMPLETED: 2,
  ARCHIVED: 3
};

export function getProjectStatus(status) {
  const statusMap = {
    [PROJECT_STATUS.IN_PROGRESS]: '进行中',
    [PROJECT_STATUS.COMPLETED]: '已完成',
    [PROJECT_STATUS.ARCHIVED]: '已归档'
  };
  return statusMap[status] || '未知状态';
}

export function getStatusClass(status) {
  const classMap = {
    [PROJECT_STATUS.IN_PROGRESS]: 'bg-primary',
    [PROJECT_STATUS.COMPLETED]: 'bg-success',
    [PROJECT_STATUS.ARCHIVED]: 'bg-secondary'
  };
  return classMap[status] || 'bg-secondary';
}
